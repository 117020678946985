export default function colors() {
    let result = [
        '#66318f',
        '#1db24b',
        '#e5b52a',
        '#b42025',
        '#c1c2c9'
    ]
    for (var i = 0; i <= 20; i++) {
        result.push('#' + (0x1000000 + (Math.random()) * 0xffffff).toString(16).substr(1, 6))
    }
    return result
}
