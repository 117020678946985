<template>
    <div>
        <InputCell
            :edit="edit"
            type="number"
            :label="label"
            :placeholder="suffix"
            :suffix="suffix"
            v-model.number="data"
        />
        <template v-if="edit">
            <button @click="data = parseFloat((car.value * (100 - car.residualValue) / 100 / 36).toFixed(2))">Värdeminskning 3 år</button>
            <br /><i>Sätt till värdeminskning / månad sett över tre år</i>
        </template>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: Number,
            required: true
        },
        car: {
            type: Object,
            required: true
        },
        edit: {
            type: Boolean,
            required: false,
            default: false
        },
        label: {
            type: String,
            required: false,
            default: null
        },
        suffix: {
            type: String,
            required: false,
            default: null
        },
        divider: {
            type: Number,
            required: false,
            default: 1
        }
    },
    data: () => ({
        data: null
    }),
    created() {
        this.data = this.value
    },
    methods: {},
    watch: {
        data: {
            handler(to, from) {
                this.$emit('input', to)
            }
        }
    }
}
</script>
