<template>
    <v-list class="menuWrapper">
        <img class="logo" src="../../assets/images/elbilskalkyl_logo.svg" />
        <template>
            <template
                v-for="route in routesToDisplay">
                <Navigation-MenuItem
                    :route="route"
                    :key="route.to"
                />
            </template>
        </template>
    </v-list>
</template>

<script>

export default {
    props: {
        routes: {
            type: Array,
            required: false,
            default: null
        }
    },
    data() {
        return {
            routesToDisplay: []
        }
    },
    created() {
        this.createItems()
    },
    methods: {
        createItems() {
            if (this.routes) {
                this.routesToDisplay = this.routes
                return
            }

            this.routesToDisplay = []
            // Fetch routes that should be visible in menu
            this.$router.options.routes.filter(route => route.meta.type === 0 || route.meta.type === 5).forEach(route => {
                const item = {
                    to: route.path,
                    localization: route.meta.localization,
                    icon: route.meta.icon,
                    class: route.meta.class
                }
                if (route.meta.parent) {
                    item.parent = route.meta.parent[0].name
                    item.class = 'child'
                }
                this.routesToDisplay.push(item)
            })
        }
    },
    watch: {
        '$store.getters.adGroups'(to) {
            this.createItems()
        },
        routes: {
            handler(to, from) {
                this.createItems()
            },
            deep: true
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/sass/main.scss";

.menuWrapper {
    min-width: 59px;
    z-index: 100;
    position: relative;
    padding-bottom: 100px;
}

.logo {
    margin: 24px;
}

</style>
