<template>
    <div class="dataTableWrapper">
        <div class="progress" v-if="!data.result">
            <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
                aria-valuenow="75"
                aria-valuemin="0" aria-valuemax="100"
                style="width: 75%"></div>
        </div>
        <input class="form-control primary" type="text"
            :placeholder="translate('common.search')"
            v-if="showSearch && data.result && parseData(data.result).columns"
            v-model="search">

        <button class="secondary" v-if="showDownload.excel">
            <json-excel
                class="download excel"
                :data="data.result"
                :name="`${showDownload.fileName}.xls`">
                {{ translate('common.dataTable.downloadExcel') }}
                <b-icon icon="file-earmark-spreadsheet" />
            </json-excel>
        </button>

        <button class="secondary" v-if="showDownload.csv">
            <download-csv
                class="download excel"
                :data="data.result"
                :name="`${showDownload.fileName}.csv`">
                {{ translate('common.dataTable.downloadCsv') }}
                <b-icon icon="file-earmark-arrow-down" />
            </download-csv>
        </button>

        <table :class="classes" v-if="data.result">
            <thead>
                <tr
                    v-if="columns">
                    <template v-for="column in columns">
                        <th
                            v-if="!column.hidden"
                            @click="setOrderBy(column.name)"
                            :class="column.class"
                            :key="column.name"
                            :style="{ width: column.width ? column.width : 'auto' }"
                        >
                            {{ column.title }}
                        </th>
                    </template>
                </tr>
                <tr
                    v-else-if="parseData(data.result).columns">
                    <template v-for="column in parseData(data.result).columns">
                        <th
                            v-if="!column.hidden"
                            @click="setOrderBy(column)"
                            :key="column.key"
                        >
                            {{ column.key }}
                        </th>
                    </template>
                </tr>
            </thead>

            <tbody v-if="columns">
                <tr
                    v-for="(row, index) in parseData(data.result).rows"
                    :class="$listeners.onSelectRow ? row.class + ', clickable': row.class"
                    :key="index">
                    <template v-for="(column, key) in columns">
                        <td
                            :class="column.class"
                            v-if="!column.hidden && column.component && column.data"
                            :key="key + '_' + column">
                            <component
                                :is="column.component"
                                :data="column.data(cell(row, column).value)"
                                :properties="column.properties" />
                        </td>
                        <slot v-if="!column.hidden && column.component">
                            <Telemetry class="telemetryWrapper"
                                v-if="column && column.type === 'json'"
                                :telemetry="cell(row, column).value"
                                :enableChart="false">
                            </Telemetry>
                            <!--<component v-else-if="column.data" :is="column.component" :data="column.data(row)">{{ row[column.name] }}</component>-->
                            <component v-else
                                :is="column.component"
                                :data="row"
                                @change="onChangeRow">
                                {{ row[column.name] }}
                            </component>
                        </slot>
                        <template v-else>
                            <td :key="key + '_' + column" @click="onSelectRow(row)"
                                v-if="!column.hidden && column && column.type === 'object'"
                                :class="cell(row, column).class"
                                v-html="cell(row, column).value">
                            </td>
                            <td :key="key + '_' + column" @click="onSelectRow(row)"
                                :class="column.class"
                                v-else-if="!column.hidden && column.name.indexOf('.') > 0">
                                {{ getObjectFromDotString(row, column, column.name) }}
                            </td>
                            <td :key="key + '_' + column" @click="onSelectRow(row)"
                                :class="column.class"
                                v-else-if="!column.hidden && column && column.type === 'dateTime'">
                                {{ formatDate(row[column.name], column.dateTimeFormat) }}
                            </td>
                            <td :key="key + '_' + column" @click="onSelectRow(row)"
                                :class="column.class"
                                v-else-if="!column.hidden"
                                v-html="row[column.name]"></td>
                        </template>
                    </template>
                </tr>
            </tbody>
            <tbody v-else-if="parseData(data.result).columns">
                <tr
                    v-for="(row, index) in parseData(data.result).rows"
                    @click="onSelectRow(row)"
                    :key="index">
                    <template v-for="(column, key) in row">
                        <td
                            :key="key + '_' + column"
                            v-if="column && !column.hidden && column.type === 'object'"
                            :class="cell(row, column).class"
                            v-html="cell(row, column).value">
                        </td>
                        <td
                            v-else-if="!columns || (!column.hidden && columns.find(c => c.name === key))"
                            :key="key + '_' + column">
                            <span
                                v-if="columns && columns.find(c => c.name === key) && columns.find(c => c.name === key).type === 'dateTime'"
                                v-html="formatDate(column)"></span>
                            <span
                                v-else
                                v-html="column"></span>
                        </td>
                    </template>
                </tr>
            </tbody>
            <tbody v-if="!parseData(data.result).columns">
                <tr>
                    <td v-if="noDataText">{{ noDataText }}</td>
                    <td v-else>Ingen data</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
import Vue from 'vue'
import moment from 'moment'
import i18n from 'i18next'
import JsonCSV from 'vue-json-csv'
import JsonExcel from 'vue-json-excel'
Vue.component('downloadCsv', JsonCSV)
export default {
    props: {
        data: {
            type: Object,
            required: true
        },
        noDataText: {
            type: String,
            required: false,
            default: 'Missing data'
        },
        columns: {
            type: Array,
            required: false,
            default: null
        },
        showSearch: {
            type: Boolean,
            required: false,
            default: true
        },
        route: {
            type: Object,
            required: false,
            default: null
        },
        showDownload: {
            type: Object,
            required: false,
            default: () => {
                return {
                    excel: false
                }
            }
        },
        options: {
            type: Object,
            required: false,
            default: () => {
                return {
                    orderBy: null,
                    ascending: true
                }
            }
        },
        classes: {
            type: String,
            required: false,
            default: 'primary'
        }
    },
    data() {
        return {
            search: '', // search input,
            orderBy: null,
            ascending: true
        }
    },
    components: {
        JsonCSV,
        JsonExcel
    },
    created() {
        this.orderBy = this.options.orderBy
        this.ascending = this.options.ascending
    },
    methods: {
        setOrderBy(orderBy) {
            if (this.orderBy === orderBy) {
                this.ascending = !this.ascending
                return
            }
            this.orderBy = orderBy
        },
        parseData(d) {
            if (d.length <= 0 || !d[0]) {
                return {}
            }

            let columns = Object.keys(d[0]).map(c => {
                return {
                    key: c
                }
            })

            var result = {
                columns: columns,
                rows: filterAndSort(d, this.search, this.orderBy, this.ascending)
            }
            return result
        },
        onSelectRow(id) {
            this.$emit('onSelectRow', id)
        },
        formatDate(s, format) {
            if (!s) {
                return '-'
            }
            if (!format) {
                format = 'YYYY-MM-DD'
            }
            return moment(s).format(format)
        },
        getObjectFromDotString(row, column, columnPath) {
            return getObjectFromDotString(row, column, columnPath)
        },
        cell(row, column) {
            let result = {
                class: column.class,
                value: row[column.name]
            }
            let o = null
            if (column.name.indexOf('.') > 0) {
                o = this.getObjectFromDotString(row, column, column.name)
            } else {
                o = row[column.name]
            }

            if (typeof o === 'object') {
                if (o == null) {
                    return {}
                }

                // This is an object that should keep a value
                if (typeof o.value === 'number') {
                    result.value = this.formatNumber(o.value)
                } else {
                    result.value = o.value
                }

                if (o.class) {
                    result.class = result.class ? result.class + ' ' + o.class : o.class
                }
            } else if (typeof o === 'number') {
                result.value = this.formatNumber(o)
            } else {
                result.value = o
            }
            return result
        },
        translate(key) {
            return i18n.t(key)
        },
        formatNumber(x) {
            return x != null ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') : null
        },
        onChangeRow(row) {
            this.$emit('onChangeRow', row)
        }
    }
}

function getObjectFromDotString(row, column, columnPath) {
    const value = columnPath.split('.').reduce((o, i) => o[i], row)
    if (!value) {
        return null
    }
    if (column && column.type === 'dateTime') {
        return this.formatDate(value, column.dateTimeFormat)
    }
    return value
}

function filterAndSort(objects, search, orderBy, ascending) {
    var result = objects.filter(function(o) {
        for (var key in o) {
            if ((o[key] + '').toLowerCase().indexOf(search.toLowerCase()) > -1) {
                return true
            }
        }
    })

    if (orderBy) {
        return result.sort((a, b) => {
            const asc = (ascending ? 1 : -1)
            const ao = getObjectFromDotString(a, null, orderBy) // a[orderBy]
            const bo = getObjectFromDotString(b, null, orderBy) // b[orderBy]
            if (!ao && !bo) {
                return 0
            } else if (!ao) {
                return -1 * asc
            } else if (!bo) {
                return 1 * asc
            }

            if (typeof ao === 'number') {
                return (ao - bo) * asc
            } else if (typeof ao === 'object') {
                return asc * (ao.value + '').localeCompare(bo.value + '')
            }
            return asc * (ao + '').localeCompare(bo + '')
        })
    }
    return result
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../assets/sass/main.scss";

.datatable, table {
    width: 100%;
}
th,
td {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 10px;
}

th {
    font-weight: bold;
    cursor: pointer;
    text-align: left;
}

tr.clickable td {
    cursor: pointer;
}

table tr:hover td {
    background: rgba(100,100,100,0.1);
}

.highlight {
    background: $table_highlight_odd;
}

input {
    margin-bottom: 16px !important;
}

.download {
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
}

.dataTableWrapper {
    padding-top: 16px;
}
</style>
