import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from './views/Home.vue'
// import System from './views/System.vue'

Vue.use(VueRouter)

const type = {
    mainMenu: 0,
    subMenu: 1,
    loggedOut: 2,
    print: 3,
    other: 4,
    mainMenuSub: 5,
    tab: 6
}

const routes = [{
    path: '/',
    name: 'home',
    component: Home,
    meta: {
        localization: 'menu.home',
        type: type.mainMenu,
        icon: 'home'
    }
}, /* {
    path: '/system',
    name: 'system',
    component: System,
    meta: {
        localization: 'menu.system',
        type: type.mainMenu,
        icon: 'mdi-view-grid'
    }
},
{
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('./views/About.vue'),
    meta: {
        localization: 'menu.about',
        type: type.mainMenu,
        icon: 'mdi-information-outline'
    }
}, */
{
    path: '/sheet',
    name: 'sheet',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */'./views/Sheet.vue'),
    meta: {
        localization: 'menu.sheet',
        type: type.mainMenu,
        icon: 'sheet'
    }
},
{
    path: '/apps',
    name: 'apps',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */'./views/Apps.vue'),
    meta: {
        localization: 'menu.apps',
        type: type.mainMenu,
        icon: 'apps'
    }
},

{
    path: '/cars/compare',
    name: 'cars',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('./views/Cars.vue'),
    meta: {
        localization: 'menu.cars',
        type: type.mainMenu,
        icon: 'cars'
    },
    children: [
        {
            path: '/cars/compare',
            name: 'cars/compare',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import(/* webpackChunkName: "about" */'./components/CompareCars.vue'),
            meta: {
                localization: 'menu.cars',
                type: type.tab,
                icon: 'cars'
            }
        },
        {
            path: '/cars/rank',
            name: 'cars/rank',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import(/* webpackChunkName: "about" */'./components/RankCars.vue'),
            meta: {
                localization: 'menu.cars',
                type: type.tab,
                icon: 'cars'
            }
        }
    ]
},
{
    path: '/cars/add',
    name: 'cars/add',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */'./views/AddCar.vue'),
    meta: {
        localization: 'menu.cars',
        type: type.subMenu,
        icon: 'cars'
    }
},
{
    path: '/cars/:id',
    name: 'cars/car',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */'./views/Car.vue'),
    meta: {
        localization: 'menu.cars',
        type: type.subMenu,
        icon: 'mdi-information-outline'
    }
},

{
    path: '/brf',
    name: 'brf',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */'./views/Brf.vue'),
    meta: {
        localization: 'menu.brf',
        type: type.mainMenu,
        icon: 'brf'
    }
}
]

const router = new VueRouter({
    routes
})

export default router
