<template>
    <div class="card" @click="$router.push(path)">
        <img class="mt-4" :src="imageSrc" />
        <h2>{{ title }}</h2>
        <p>{{ paragraph }}</p>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            required: true
        },
        paragraph: {
            type: String,
            required: true
        },
        image: {
            type: String,
            required: false,
            default: 'settings'
        },
        path: {
            type: String,
            required: true
        }
    },
    computed: {
        imageSrc() {
            return require(`../assets/images/${this.image}.svg`)
        }
    }
}
</script>

<style lang="scss" scoped>
.card {
    position: relative;
    font-size: 1.1em;
    background: #f0f0f09a;
    padding: 4px 30px;
    text-decoration: none;
    font-weight: 500;
    color: #111;
    transition: 0.2s;
    cursor: pointer;
    min-height: 380px;
    border-left: thick double #364557;
    box-shadow:  3px 3px 7px #d4d4d4,
            -3px -3px 7px #ffffff;

  h2 {
    font-size: 1.3em;
    text-transform: uppercase;
    letter-spacing: 2px;
  }

  p {
        margin-top: 10px;
  }

  img {
    width: 100%;
    max-width: 400px;
    height: 200px;
    display:block;
    margin:auto;
  }
}

.card:hover {
    box-shadow:  7px 7px 14px #d4d4d4,
            -7px -7px 14px #ffffff;
}

@media (max-width: 1810px)
{
  .card {
    padding: 6px;
  }
  .text h2
  {
    font-size: 1.2em;
  }
  .text p
  {
    font-size: .8em;
  }
}

@media (max-width: 1621px)
{
  .card {
    padding: 6px;
  }
  .text h2
  {
    font-size: 1em;
  }
  .text p
  {
    font-size: .7em;
  }
}

@media (max-width: 991px)
{
  .card {
    padding: 4px 12px;
  }
}

</style>
