<template>
    <div>
        <v-combobox
            clearable
            multiple
            outlined
            persistent-hint
            hide-selected
            small-chips
            :items="list.map(car => {
                return car.model
            })"
            v-model="filter"
        />

        <h2>Effekt över tid</h2>
        <CarChart-PowerTime :car="car" :filter="filter" />

        <h2>Adderade km per laddad minut</h2>
        <p>En bil som har låg förbrukning kan ladda fler mil per minut. Detta diagrammet visar antal adderade kilometer per laddad minut för att kunna jämföra vem som laddar flest mil på kortast tid.</p>
        <CarChart-KmPerMinute :car="car" :filter="filter" />

        <h2>Laddeffekt</h2>
        <CarChart-PowerPercentage :car="car" :filter="filter" />

        <h2>Räckvidd</h2>
        <!--<CarChart-Range :car="car" />-->
        <CarChart-Range :car="car" :filter="filter" />
        Källa: <a :href="car.evdb ? car.evdb : `https://ev-database.org/search/?q=${car.make.replace(/ /g, '+')}+${car.model.replace(/ /g, '+')}+${car.version.replace(/ /g, '+')}`">EV-Database/{{ car.make }} {{ car.model }} {{ car.version }}</a>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    props: {
        car: {
            type: Object,
            required: true
        }
    },
    data: () => ({
        filter: []
    }),
    components: {},
    async mounted() {
        if (this.$route.query.cars) {
            this.filter = this.$route.query.cars.split(',')
        }
    },
    computed: {
        ...mapGetters({
            list: 'cars/list'
        })
    },
    methods: {
        addParamsToLocation(params) {
            const query = { ...this.$route.query, cars: params.cars }
            console.log(query, this.$route.query)
            if (query.cars === this.$route.query.cars) {
                return
            }
            this.$router.replace({ query })
        }
    },
    watch: {
        filter: {
            handler(to, from) {
                if (this.filter) {
                    this.addParamsToLocation({ cars: this.filter.join(',') })
                } else {
                    this.addParamsToLocation({ cars: null })
                }
            }
        }
    }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../../assets/sass/main.scss";
</style>
