<template>
    <v-dialog v-model="dialog" persistent max-width="290">
        <v-card>
            <v-card-title class="headline">Information</v-card-title>
            <v-card-text>
                <a href="https://storyset.com/transport">Transport illustrations by Storyset</a>
            </v-card-text>
            <v-card-actions>
                <v-btn color="grey darken-1" text @click="closeDialog">Stäng</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
export default {
    data() {
        return {
            dialog: true
        }
    },
    methods: {
        closeDialog() {
            this.$emit('closeDialog')
            this.dialog = false
        }
    }
}
</script>
<style lang="scss" scoped>
h3 {
  font-size: 25px;
  line-height: 40px;
  font-weight: 300;
}
</style>>
