import 'core-js/stable'
import 'regenerator-runtime/runtime'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import Vuelidate from 'vuelidate'
import VueFormulate from '@braid/vue-formulate'
import i18next from 'i18next'

// Components
import './components'

Vue.use(Vuelidate)
Vue.use(VueFormulate)
// Localization
Vue.use(i18next)
// import localeEn from './locales/en/en.json'
import localeSv from './locales/sv/sv.json'
var resources = {}
// resources.en = localeEn
resources.sv = localeSv
Vue.config.lang = 'sv'

let i18nextConfig = {
    lng: navigator.language || // All browsers
        navigator.userLanguage, // IE <= 10
    fallbackLng: 'sv',
    resources
}

router.beforeEach((to, from, next) => {
    next()
})

if (process.env.NODE_ENV === 'debug') {
    i18nextConfig.debug = true
}

i18next.init(i18nextConfig)
var localization = {
    methods: {
        translate(key) {
            return i18next.t(key)
        },
        language() {
            return Vue.config.lang
        }
    }
}

Vue.mixin(localization)

// Config
Vue.config.productionTip = false

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')
