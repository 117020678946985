import ApiCaller from './api.js'
const api = new ApiCaller()
export default class Cars {
    constructor() {
        this.cars = null
    }
    async list() {
        const result = await api.run('/cars?code=FsxVgWfPFUjDcbgWdbuqDddaWCtZa9c0LzrLwSpa67FFaBCiHn8Kfw==', {})
        this.cars = result.data
        return this.cars
    }

    async fetch(id) {
        // TODO:
        const result = await this.list()
        return result.find(item => item.id === id)
    }

    async add(item) {
        // TODO:
        this.mockList.push(item)
        return item
    }

    async update(id, model) {
        // TODO:
        return model
    }
}
