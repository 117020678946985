import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import { Cars } from '@/helpers'
const cars = new Cars()
Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        isLoading: false
    },
    mutations: {
        setLoading(state, isLoading) {
            state.isLoading = isLoading
        }
    },
    getters: {
        isLoading: state => {
            return state.isLoading
        }
    },
    actions: {},
    modules: {
        cars: {
            strict: process.env.NODE_ENV !== 'production',
            namespaced: true,
            state: {
                /* eslint-disable max-len */
                list: [{
                    id: '0f529a8c-08e5-4814-903f-573bd5e2df36',
                    evdb: 'https://ev-database.org/car/1321/Tesla-Model-3-Long-Range-Dual-Motor',
                    make: 'Tesla',
                    model: 'Model 3',
                    version: 'Long Range Dual Motor',
                    tags: ['tesla'],
                    img: '0f529a8c-08e5-4814-903f-573bd5e2df36.png',
                    color: '#bb0000',
                    driven: true,
                    battery: {
                        real: null,
                        usable: 78
                    },
                    charging: {
                        max: 250,
                        curves: [{
                            source: 'Jeppe Ekwall',
                            charger: {
                                max: 300,
                                where: 'Supercharger v3'
                            },
                            data: [
                                {
                                    time: 0,
                                    power: 248,
                                    percentage: 10
                                }, {
                                    time: 1,
                                    power: 235,
                                    percentage: 15
                                }, {
                                    time: 1.7,
                                    power: 230,
                                    percentage: 20
                                }, {
                                    time: 4,
                                    power: 218,
                                    percentage: 30
                                }, {
                                    time: 6.1,
                                    power: 185,
                                    percentage: 40
                                }, {
                                    time: 10,
                                    power: 132,
                                    percentage: 50
                                }, {
                                    time: 13.5,
                                    power: 100,
                                    percentage: 60
                                }, {
                                    time: 18.5,
                                    power: 80,
                                    percentage: 70
                                }, {
                                    time: 26,
                                    power: 50,
                                    percentage: 80
                                }, {
                                    time: 37,
                                    power: 32,
                                    percentage: 90
                                }, {
                                    time: 54,
                                    power: 10,
                                    percentage: 98
                                }]
                        }, {
                            source: 'Daniel Nord',
                            charger: {
                                max: 150,
                                where: 'Circle-K Flädie'
                            },
                            data: [
                                /* { //16:09:35
                                                    time: 0,
                                                    power: 23,
                                                    percentage: 6
                                                }, {
                                                    time: 1,//16:10:50
                                                    power: 113,
                                                    percentage: 7
                                                }, */
                                {
                                    time: 0,
                                    power: 126,
                                    percentage: 10
                                }, {
                                    time: 1, // 16:12
                                    power: 139,
                                    percentage: 12
                                }, {
                                    time: 10, // 16:21
                                    power: 138,
                                    percentage: 40
                                }, {
                                    time: 15, // 16:26
                                    power: 110,
                                    percentage: 54
                                }, {
                                    time: 20, // 16:31
                                    power: 85,
                                    percentage: 64
                                }, {
                                    time: 25, // 16:36
                                    power: 65,
                                    percentage: 74
                                }, {
                                    time: 30, // 16:41
                                    power: 50,
                                    percentage: 80
                                }, {
                                    time: 35, // 16:46
                                    power: 43,
                                    percentage: 86
                                }, {
                                    time: 38,
                                    power: 38,
                                    percentage: 90
                                }
                            ]
                        }]
                    },
                    consumption: {
                        'real': {
                            'range': 490,
                            'vehicle': 155
                        },
                        'estimation': {
                            'mild': {
                                'city': 107,
                                'highway': 163,
                                'combined': 133
                            },
                            'cold': {
                                'city': 163,
                                'highway': 214,
                                'combined': 185
                            }
                        },
                        'wltp': {
                            'low': {
                                'range': null,
                                'rated': null,
                                'vehicle': null
                            },
                            'high': {
                                'range': null,
                                'rated': null,
                                'vehicle': null
                            }
                        },
                        'speed90': 133,
                        'speed120': 163,
                        'winterMultiplicator': 1.3909774436090225
                    },
                    price: 780000,
                    leasing: {
                        price: 6149,
                        wheels: 419,
                        insurance: 680,
                        url: 'https://tesla3.dealerpad.net/se/tesla3?session=62cf3f8319f3455cb5594dd03f38126e&make=Tesla&model=Model%203&version=Long-Range%20Dual%20Motor%20AWD'
                    },
                    tax: 315,
                    service: 0
                }, {
                    id: 'da7d7d75-f947-463c-bb9d-db6ab7e0fa1a',
                    make: 'Volkswagen',
                    model: 'ID.3',
                    version: 'Pro Performance',
                    tags: ['vw'],
                    img: 'da7d7d75-f947-463c-bb9d-db6ab7e0fa1a.webp',
                    color: '#0000bb',
                    driven: true,
                    battery: {
                        real: null,
                        usable: 58
                    },
                    charging: {
                        max: 100,
                        curves: [{
                            source: 'Elektroautomobil',
                            charger: {
                                max: null,
                                where: 'Ionity'
                            },
                            data: [{
                                time: 0,
                                power: 100,
                                percentage: 8
                            }, {
                                time: 2,
                                power: 102,
                                percentage: 12
                            }, {
                                time: 7,
                                power: 103,
                                percentage: 28
                            }, {
                                time: 8,
                                power: 100,
                                percentage: 30
                            }, {
                                time: 12,
                                power: 86,
                                percentage: 40
                            }, {
                                time: 17,
                                power: 72,
                                percentage: 50
                            }, {
                                time: 22,
                                power: 62,
                                percentage: 60
                            }, {
                                time: 28,
                                power: 50,
                                percentage: 70
                            }, {
                                time: 35,
                                power: 52,
                                percentage: 80
                            }, {
                                time: 38,
                                power: 40,
                                percentage: 85
                            }, {
                                time: 44,
                                power: 28,
                                percentage: 90
                            }, {
                                time: 59,
                                power: 24,
                                percentage: 98
                            }, {
                                time: 72,
                                power: 24,
                                percentage: 98
                            }]
                        }]
                    },
                    consumption: {
                        'real': {
                            'range': 350,
                            'vehicle': 166
                        },
                        'estimation': {
                            'mild': {
                                'city': 112,
                                'highway': 178,
                                'combined': 143
                            },
                            'cold': {
                                'city': 171,
                                'highway': 232,
                                'combined': 197
                            }
                        },
                        'wltp': {
                            'low': {
                                'range': 426,
                                'rated': 134,
                                'vehicle': 136
                            },
                            'high': {
                                'range': 420,
                                'rated': 156,
                                'vehicle': 138
                            }
                        },
                        'speed90': 143,
                        'speed120': 178,
                        'winterMultiplicator': 1.3776223776223777
                    },
                    price: 437900,
                    leasing: {
                        price: 3500,
                        wheels: 287,
                        insurance: 680
                    },
                    tax: 315,
                    service: 0
                }, {
                    id: '4394fa33-58f6-49ed-960a-c022af94d27c',
                    make: 'Porsche',
                    model: 'Taycan',
                    version: '4S',
                    tags: ['vw'],
                    img: '4394fa33-58f6-49ed-960a-c022af94d27c.jpg',
                    color: '#00bb00',
                    driven: false,
                    battery: {
                        real: null,
                        usable: 83.7
                    }, // Taycan 4S
                    charging: {
                        max: 270,
                        curves: [{
                            source: 'Daniel Nord',
                            charger: {
                                max: 150,
                                where: 'Circle-K Flädie'
                            },
                            data: [{
                                time: 0,
                                power: 242,
                                percentage: 10
                            }, {
                                time: null,
                                power: 247,
                                percentage: 20
                            }, {
                                time: null,
                                power: 253,
                                percentage: 30
                            }, {
                                time: null,
                                power: 153,
                                percentage: 40
                            }, {
                                time: 11,
                                power: 154,
                                percentage: 50
                            }, {
                                time: null,
                                power: 158,
                                percentage: 60
                            }, {
                                time: null,
                                power: 163,
                                percentage: 70
                            }, {
                                time: null,
                                power: 90,
                                percentage: 80
                            }, {
                                time: 33,
                                power: 81,
                                percentage: 90
                            }]
                        }]
                    },
                    consumption: {
                        'real': {
                            'range': 435,
                            'vehicle': 192
                        },
                        'estimation': {
                            'mild': {
                                'city': 138,
                                'highway': 204,
                                'combined': 169
                            },
                            'cold': {
                                'city': 202,
                                'highway': 262,
                                'combined': 229
                            }
                        },
                        'wltp': {
                            'low': {
                                'range': 464,
                                'rated': 219,
                                'vehicle': 180
                            },
                            'high': {
                                'range': 389,
                                'rated': 260,
                                'vehicle': 215
                            }
                        },
                        'speed90': 169,
                        'speed120': 204,
                        'winterMultiplicator': 1.3550295857988166
                    },
                    price: 1000000,
                    leasing: {
                        price: 10000,
                        wheels: 287,
                        insurance: 680
                    },
                    tax: 315,
                    service: 0
                }, {
                    id: '5394fa33-58f6-49ed-960a-c022af94d27c',
                    make: 'Audi',
                    model: 'e-tron S Sportback',
                    version: '55',
                    tags: ['audi'],
                    img: '5394fa33-58f6-49ed-960a-c022af94d27c.webp',
                    color: '#00bb00',
                    driven: false,
                    battery: {
                        real: null,
                        usable: 86.5
                    },
                    charging: {
                        max: 270,
                        curves: [{
                            source: 'Daniel Nord',
                            charger: {
                                max: 150,
                                where: 'Circle-K Flädie'
                            },
                            data: [{
                                time: 0,
                                power: 140,
                                percentage: 10
                            }, {
                                time: null,
                                power: 142,
                                percentage: 20
                            }, {
                                time: null,
                                power: 143,
                                percentage: 30
                            }, {
                                time: null,
                                power: 145,
                                percentage: 40
                            }, {
                                time: 14,
                                power: 147,
                                percentage: 50
                            }, {
                                time: null,
                                power: 149,
                                percentage: 60
                            }, {
                                time: null,
                                power: 152,
                                percentage: 70
                            }, {
                                time: null,
                                power: 129,
                                percentage: 80
                            }, {
                                time: 31,
                                power: 83,
                                percentage: 90
                            }]
                        }]
                    },
                    consumption: {
                        'real': {
                            'range': 335,
                            'vehicle': 258
                        },
                        'estimation': {
                            'mild': {
                                'city': 186,
                                'highway': 284,
                                'combined': 234
                            },
                            'cold': {
                                'city': 258,
                                'highway': 353,
                                'combined': 304
                            }
                        },
                        'wltp': {
                            'low': {
                                'range': 380,
                                'rated': 256,
                                'vehicle': 228
                            },
                            'high': {
                                'range': 347,
                                'rated': 281,
                                'vehicle': 249
                            }
                        },
                        'speed90': 234,
                        'speed120': 284,
                        'winterMultiplicator': 1.2991452991452992
                    },
                    price: 1000000,
                    leasing: {
                        price: 10000,
                        wheels: 287,
                        insurance: 680
                    },
                    tax: 315,
                    service: 0
                }, {
                    id: '6394fa33-58f6-49ed-960a-c022af94d27c',
                    make: 'Audi',
                    model: 'e-tron GT',
                    version: '60',
                    tags: ['audi'],
                    img: '6394fa33-58f6-49ed-960a-c022af94d27c.jpg',
                    color: '#00bb00',
                    driven: false,
                    battery: {
                        real: null,
                        usable: 83.7
                    }, // Audi GT
                    charging: {
                        max: 270,
                        curves: [{
                            source: 'Daniel Nord',
                            charger: {
                                max: 150,
                                where: 'Circle-K Flädie'
                            },
                            data: [{
                                time: 0,
                                power: 252,
                                percentage: 10
                            }, {
                                time: null,
                                power: 261,
                                percentage: 20
                            }, {
                                time: null,
                                power: 266,
                                percentage: 30
                            }, {
                                time: null,
                                power: 268,
                                percentage: 40
                            }, {
                                time: 8,
                                power: 251,
                                percentage: 50
                            }, {
                                time: null,
                                power: 192,
                                percentage: 60
                            }, {
                                time: null,
                                power: 163,
                                percentage: 70
                            }, {
                                time: null,
                                power: 89,
                                percentage: 80
                            }, {
                                time: 33,
                                power: 25,
                                percentage: 90
                            }]
                        }]
                    },
                    consumption: {
                        'real': {
                            'range': 420,
                            'vehicle': 202
                        },
                        'estimation': {
                            'mild': {
                                'city': 144,
                                'highway': 218,
                                'combined': 179
                            },
                            'cold': {
                                'city': 210,
                                'highway': 279,
                                'combined': 239
                            }
                        },
                        'wltp': {
                            'low': {
                                'range': null,
                                'rated': null,
                                'vehicle': null
                            },
                            'high': {
                                'range': null,
                                'rated': null,
                                'vehicle': null
                            }
                        },
                        'speed90': 179,
                        'speed120': 218,
                        'winterMultiplicator': 1.335195530726257
                    },
                    price: 1000000,
                    leasing: {
                        price: 10000,
                        wheels: 287,
                        insurance: 680
                    },
                    tax: 315,
                    service: 0
                }, {
                    id: 'ccdee5c0-4b16-4b26-8505-d534ea10d40b',
                    make: 'Peugeot',
                    model: 'e-2008',
                    version: '',
                    tags: ['peugeot'],
                    img: 'ccdee5c0-4b16-4b26-8505-d534ea10d40b.jpg',
                    color: '#0080ff',
                    driven: true,
                    battery: {
                        real: null,
                        usable: 45
                    },
                    charging: {
                        max: 100,
                        curves: [{
                            source: 'Elektroautomobil',
                            charger: {
                                max: null,
                                where: 'Ionity'
                            },
                            data: [{
                                time: 0,
                                power: 95,
                                percentage: 8
                            }, {
                                time: 2,
                                power: 98,
                                percentage: 10
                            }, {
                                time: 3,
                                power: 95,
                                percentage: 20
                            }, {
                                time: 5,
                                power: 95,
                                percentage: 25
                            }, {
                                time: 6,
                                power: 75,
                                percentage: 27
                            }, {
                                time: 14,
                                power: 76,
                                percentage: 53
                            }, {
                                time: 15,
                                power: 72,
                                percentage: 54
                            }, {
                                time: 25,
                                power: 54,
                                percentage: 72
                            }, {
                                time: 27,
                                power: 28,
                                percentage: 76
                            }, {
                                time: 39,
                                power: 28,
                                percentage: 86
                            }, {
                                time: 40,
                                power: 11,
                                percentage: 87
                            }, {
                                time: 51,
                                power: 10,
                                percentage: 91
                            }, {
                                time: 52,
                                power: 2,
                                percentage: 92
                            }]
                        }]
                    },
                    consumption: {
                        'real': {
                            'range': 250,
                            'vehicle': 180
                        },
                        'estimation': {
                            'mild': {
                                'city': 120,
                                'highway': 200,
                                'combined': 158
                            },
                            'cold': {
                                'city': 176,
                                'highway': 257,
                                'combined': 214
                            }
                        },
                        'wltp': {
                            'low': {
                                'range': null,
                                'rated': null,
                                'vehicle': null
                            },
                            'high': {
                                'range': null,
                                'rated': null,
                                'vehicle': null
                            }
                        },
                        'speed90': 158,
                        'speed120': 200,
                        'winterMultiplicator': 1.3544303797468353
                    },
                    price: 404900,
                    leasing: {
                        price: null,
                        wheels: null,
                        insurance: null
                    },
                    tax: 315,
                    service: 0
                }, {
                    id: '328c7044-3f43-44c0-8298-b33880b8be28',
                    make: 'Polestar',
                    model: '2',
                    version: 'Long range Dual motor',
                    tags: ['polestar'],
                    img: '328c7044-3f43-44c0-8298-b33880b8be28.webp',
                    color: '#222222',
                    driven: true,
                    battery: {
                        real: null,
                        usable: 78
                    },
                    charging: {
                        max: 150,
                        curves: [{
                            source: 'Elektroautomobil',
                            charger: {
                                max: null,
                                where: 'Ionity'
                            },
                            data: [{
                                time: 0,
                                power: 120,
                                percentage: 8
                            }, {
                                time: 2,
                                power: 130,
                                percentage: 10
                            }, {
                                time: 7,
                                power: 130,
                                percentage: 28
                            }, {
                                time: 8,
                                power: 135,
                                percentage: 29
                            }, {
                                time: 9,
                                power: 120,
                                percentage: 30
                            }, {
                                time: 13,
                                power: 123,
                                percentage: 38
                            }, {
                                time: 16,
                                power: 100,
                                percentage: 46
                            }, {
                                time: 17,
                                power: 104,
                                percentage: 48
                            }, {
                                time: 18,
                                power: 82,
                                percentage: 50
                            }, {
                                time: 20,
                                power: 82,
                                percentage: 53
                            }, {
                                time: 21,
                                power: 62,
                                percentage: 54
                            }, {
                                time: 33,
                                power: 70,
                                percentage: 68
                            }, {
                                time: 34,
                                power: 30,
                                percentage: 69
                            }, {
                                time: 46,
                                power: 30,
                                percentage: 83
                            }, {
                                time: 47,
                                power: 18,
                                percentage: 84
                            }, {
                                time: 64,
                                power: 18,
                                percentage: 86
                            }, {
                                time: 65,
                                power: 4,
                                percentage: 87
                            }, {
                                time: 68,
                                power: 4,
                                percentage: 88
                            }]
                        }]
                    },
                    consumption: {
                        'real': {
                            'range': 395,
                            'vehicle': 190
                        },
                        'estimation': {
                            'mild': {
                                'city': 132,
                                'highway': 205,
                                'combined': 165
                            },
                            'cold': {
                                'city': 195,
                                'highway': 263,
                                'combined': 224
                            }
                        },
                        'wltp': {
                            'low': {
                                'range': 480,
                                'rated': 195,
                                'vehicle': 156
                            },
                            'high': {
                                'range': 450,
                                'rated': 203,
                                'vehicle': 167
                            }
                        },
                        'speed90': 165,
                        'speed120': 205,
                        'winterMultiplicator': 1.3575757575757577
                    },
                    price: 589000,
                    leasing: {
                        price: null,
                        wheels: null,
                        insurance: null
                    },
                    tax: 315,
                    service: 0
                }, {
                    id: '8f68d47c-39ab-4fde-b049-46cc6a3a12b8',
                    make: 'MG',
                    model: 'ZS EV',
                    version: '',
                    tags: ['mg'],
                    img: '8f68d47c-39ab-4fde-b049-46cc6a3a12b8.jpg',
                    color: '#bb0000',
                    driven: true,
                    battery: {
                        real: null,
                        usable: 42.5
                    },
                    charging: {
                        max: 76,
                        curves: [{
                            source: 'Elektroautomobil',
                            charger: {
                                max: null,
                                where: 'Ionity'
                            },
                            data: [{
                                time: 0,
                                power: 50,
                                percentage: 8
                            }, {
                                time: 2,
                                power: 58,
                                percentage: 10
                            }, {
                                time: 5,
                                power: 64,
                                percentage: 18
                            }, {
                                time: 6,
                                power: 64,
                                percentage: 24
                            }, {
                                time: 10,
                                power: 58,
                                percentage: 30
                            }, {
                                time: 12,
                                power: 56,
                                percentage: 38
                            }, {
                                time: 18,
                                power: 46,
                                percentage: 48
                            }, {
                                time: 20,
                                power: 44,
                                percentage: 52
                            }, {
                                time: 30,
                                power: 38,
                                percentage: 68
                            }, {
                                time: 39,
                                power: 38,
                                percentage: 80
                            }, {
                                time: 40,
                                power: 18,
                                percentage: 81
                            }, {
                                time: 57,
                                power: 18,
                                percentage: 92
                            }, {
                                time: 58,
                                power: 2,
                                percentage: 93
                            }]
                        },
                        {
                            source: 'Auto Motor & Sport',
                            charger: {
                                max: null,
                                where: 'Ionity',
                                temperature: null
                            },
                            data: [{
                                time: 0,
                                power: 79,
                                percentage: 10
                            }, {
                                time: null,
                                power: 70,
                                percentage: 20
                            }, {
                                time: null,
                                power: 59,
                                percentage: 30
                            }, {
                                time: null,
                                power: 54,
                                percentage: 40
                            }, {
                                time: 16,
                                power: 46,
                                percentage: 50
                            }, {
                                time: null,
                                power: 40,
                                percentage: 60
                            }, {
                                time: null,
                                power: 36,
                                percentage: 70
                            }, {
                                time: 36,
                                power: 36,
                                percentage: 80
                            }, {
                                time: 51,
                                power: 17,
                                percentage: 90
                            }]
                        }
                        ]
                    },
                    consumption: {
                        'real': {
                            'range': 220,
                            'vehicle': 193
                        },
                        'estimation': {
                            'mild': {
                                'city': 127,
                                'highway': 218,
                                'combined': 167
                            },
                            'cold': {
                                'city': 185,
                                'highway': 274,
                                'combined': 224
                            }
                        },
                        'wltp': {
                            'low': {
                                'range': null,
                                'rated': null,
                                'vehicle': null
                            },
                            'high': {
                                'range': null,
                                'rated': null,
                                'vehicle': null
                            }
                        },
                        'speed90': 167,
                        'speed120': 218,
                        'winterMultiplicator': 1.341317365269461
                    },
                    price: 339900,
                    leasing: {
                        price: null,
                        wheels: null,
                        insurance: null
                    },
                    tax: 315,
                    service: 0
                }, {
                    id: 'ea7d7d75-f947-463c-bb9d-db6ab7e0fa1a',
                    make: 'Volkswagen',
                    model: 'ID.4',
                    version: 'Pro Performance',
                    tags: ['vw'],
                    img: 'ea7d7d75-f947-463c-bb9d-db6ab7e0fa1a.jpg',
                    color: '#bbbb00',
                    driven: false,
                    battery: {
                        real: null,
                        usable: 77
                    },
                    charging: {
                        max: 125,
                        curves: []
                    },
                    consumption: {
                        'real': {
                            'range': 410,
                            'vehicle': 188
                        },
                        'estimation': {
                            'mild': {
                                'city': 128,
                                'highway': 205,
                                'combined': 164
                            },
                            'cold': {
                                'city': 190,
                                'highway': 261,
                                'combined': 220
                            }
                        },
                        'wltp': {
                            'low': {
                                'range': 522,
                                'rated': 171,
                                'vehicle': 148
                            },
                            'high': {
                                'range': 506,
                                'rated': 178,
                                'vehicle': 152
                            }
                        },
                        'speed90': 164,
                        'speed120': 205,
                        'winterMultiplicator': 1.3414634146341464
                    },
                    price: 437900,
                    leasing: {
                        price: 4500,
                        wheels: 287,
                        insurance: 680
                    },
                    tax: 315,
                    service: 0
                }, {
                    id: 'fa7d7d75-f947-463c-bb9d-db6ab7e0fa1a',
                    make: 'Skoda',
                    model: 'Enyaq',
                    version: 'iV 80',
                    tags: ['skoda'],
                    img: 'fa7d7d75-f947-463c-bb9d-db6ab7e0fa1a.jpg',
                    color: '#bbbbff',
                    driven: false,
                    battery: {
                        real: null,
                        usable: 77
                    },
                    charging: {
                        max: 125,
                        curves: []
                    },
                    consumption: {
                        'real': {
                            'range': 420,
                            'vehicle': 183
                        },
                        'estimation': {
                            'mild': {
                                'city': 125,
                                'highway': 200,
                                'combined': 159
                            },
                            'cold': {
                                'city': 186,
                                'highway': 257,
                                'combined': 217
                            }
                        },
                        'wltp': {
                            'low': {
                                'range': 537,
                                'rated': 152,
                                'vehicle': 143
                            },
                            'high': {
                                'range': 536,
                                'rated': 216,
                                'vehicle': 144
                            }
                        },
                        'speed90': 159,
                        'speed120': 200,
                        'winterMultiplicator': 1.3647798742138364
                    },
                    price: 479800,
                    leasing: {
                        price: 4500,
                        wheels: 287,
                        insurance: 680
                    },
                    tax: 315,
                    service: 0
                }, {
                    'id': 'd61c43bd-41a7-ad34-a4ac-fb723b111b85',
                    'name': 'BMW i3 120 Ah',
                    'make': 'BMW',
                    'model': 'i3',
                    'version': '120 Ah',
                    'tags': [],
                    'img': null,
                    'color': '#666666',
                    'driven': false,
                    'battery': {
                        'real': 42.2,
                        'useable': 37.9
                    },
                    'charging': {
                        'max': 49,
                        'curves': []
                    },
                    'consumption': {
                        'real': {
                            'range': 235,
                            'vehicle': 161
                        },
                        'estimation': {
                            'mild': {
                                'city': 105,
                                'highway': 176,
                                'combined': 138
                            },
                            'cold': {
                                'city': 161,
                                'highway': 230,
                                'combined': 190
                            }
                        },
                        'wltp': {
                            'low': {
                                'range': null,
                                'rated': null,
                                'vehicle': null
                            },
                            'high': {
                                'range': null,
                                'rated': null,
                                'vehicle': null
                            }
                        },
                        'speed90': 138,
                        'speed120': 176,
                        'winterMultiplicator': 1.3768115942028984
                    },
                    'price': null,
                    'leasing': {
                        'price': null,
                        'wheels': null,
                        'insurance': null
                    },
                    'tax': 315,
                    'service': 0
                }, {
                    'id': 'f65bbe3c-58d8-21e2-f1a7-512d12153aca',
                    'name': 'Hyundai IONIQ Electric',
                    'make': 'Hyundai',
                    'model': 'IONIQ',
                    'version': 'Electric',
                    'tags': [],
                    'img': null,
                    'color': '#666666',
                    'driven': false,
                    'battery': {
                        'real': 40.4,
                        'useable': 38.3
                    },
                    'charging': {
                        'max': 44,
                        'curves': []
                    },
                    'consumption': {
                        'real': {
                            'range': 250,
                            'vehicle': 153
                        },
                        'estimation': {
                            'mild': {
                                'city': 105,
                                'highway': 167,
                                'combined': 132
                            },
                            'cold': {
                                'city': 163,
                                'highway': 219,
                                'combined': 187
                            }
                        },
                        'wltp': {
                            'low': {
                                'range': null,
                                'rated': null,
                                'vehicle': null
                            },
                            'high': {
                                'range': null,
                                'rated': null,
                                'vehicle': null
                            }
                        },
                        'speed90': 132,
                        'speed120': 167,
                        'winterMultiplicator': 1.4166666666666667
                    },
                    'price': null,
                    'leasing': {
                        'price': null,
                        'wheels': null,
                        'insurance': null
                    },
                    'tax': 315,
                    'service': 0
                }, {
                    'id': '7fb119ed-ab3b-afd9-439e-c9de43e62fc0',
                    'name': 'Hyundai Kona Electric 39 kWh',
                    'make': 'Hyundai',
                    'model': 'Kona',
                    'version': 'Electric 39 kWh',
                    'tags': [],
                    'img': null,
                    'color': '#666666',
                    'driven': false,
                    'battery': {
                        'real': 42,
                        'useable': 39.2
                    },
                    'charging': {
                        'max': 50,
                        'curves': []
                    },
                    'consumption': {
                        'real': {
                            'range': 250,
                            'vehicle': 157
                        },
                        'estimation': {
                            'mild': {
                                'city': 103,
                                'highway': 170,
                                'combined': 135
                            },
                            'cold': {
                                'city': 160,
                                'highway': 224,
                                'combined': 187
                            }
                        },
                        'wltp': {
                            'low': {
                                'range': null,
                                'rated': null,
                                'vehicle': null
                            },
                            'high': {
                                'range': null,
                                'rated': null,
                                'vehicle': null
                            }
                        },
                        'speed90': 135,
                        'speed120': 170,
                        'winterMultiplicator': 1.385185185185185
                    },
                    'price': null,
                    'leasing': {
                        'price': null,
                        'wheels': null,
                        'insurance': null
                    },
                    'tax': 315,
                    'service': 0
                }, {
                    'id': 'd5badc86-1388-b3e8-d1d1-4f9144877080',
                    'name': 'Mini Cooper SE',
                    'make': 'Mini',
                    'model': 'Cooper',
                    'version': 'SE',
                    'tags': [],
                    'img': null,
                    'color': '#666666',
                    'driven': false,
                    'battery': {
                        'real': 32.6,
                        'useable': 28.9
                    },
                    'charging': {
                        'max': 49,
                        'curves': []
                    },
                    'consumption': {
                        'real': {
                            'range': 185,
                            'vehicle': 156
                        },
                        'estimation': {
                            'mild': {
                                'city': 103,
                                'highway': 170,
                                'combined': 134
                            },
                            'cold': {
                                'city': 161,
                                'highway': 222,
                                'combined': 186
                            }
                        },
                        'wltp': {
                            'low': {
                                'range': 234,
                                'rated': 152,
                                'vehicle': 124
                            },
                            'high': {
                                'range': 203,
                                'rated': 176,
                                'vehicle': 142
                            }
                        },
                        'speed90': 134,
                        'speed120': 170,
                        'winterMultiplicator': 1.3880597014925373
                    },
                    'price': null,
                    'leasing': {
                        'price': null,
                        'wheels': null,
                        'insurance': null
                    },
                    'tax': 315,
                    'service': 0
                }, {
                    'id': '00c19e37-a155-c3c8-4d19-6bdeb318b555',
                    'name': 'Opel Corsa-e',
                    'make': 'Opel',
                    'model': 'Corsa-e',
                    'version': '',
                    'tags': [],
                    'img': null,
                    'color': '#666666',
                    'driven': false,
                    'battery': {
                        'real': 50,
                        'useable': 45
                    },
                    'charging': {
                        'max': 99,
                        'curves': []
                    },
                    'consumption': {
                        'real': {
                            'range': 275,
                            'vehicle': 164
                        },
                        'estimation': {
                            'mild': {
                                'city': 110,
                                'highway': 180,
                                'combined': 143
                            },
                            'cold': {
                                'city': 167,
                                'highway': 231,
                                'combined': 196
                            }
                        },
                        'wltp': {
                            'low': {
                                'range': null,
                                'rated': null,
                                'vehicle': null
                            },
                            'high': {
                                'range': null,
                                'rated': null,
                                'vehicle': null
                            }
                        },
                        'speed90': 143,
                        'speed120': 180,
                        'winterMultiplicator': 1.3706293706293706
                    },
                    'price': null,
                    'leasing': {
                        'price': null,
                        'wheels': null,
                        'insurance': null
                    },
                    'tax': 315,
                    'service': 0
                }, {
                    'id': '489171a7-21fe-8d47-2079-ff39f1dfebb9',
                    'name': 'Tesla Model 3 Standard Range Plus',
                    'make': 'Tesla',
                    'model': 'Model',
                    'version': '3 Standard Range Plus',
                    'tags': [],
                    'img': null,
                    'color': '#666666',
                    'driven': false,
                    'battery': {
                        'real': 55,
                        'useable': 50
                    },
                    'charging': {
                        'max': 170,
                        'curves': []
                    },
                    'consumption': {
                        'real': {
                            'range': 340,
                            'vehicle': 147
                        },
                        'estimation': {
                            'mild': {
                                'city': 99,
                                'highway': 154,
                                'combined': 125
                            },
                            'cold': {
                                'city': 156,
                                'highway': 204,
                                'combined': 175
                            }
                        },
                        'wltp': {
                            'low': {
                                'range': null,
                                'rated': null,
                                'vehicle': null
                            },
                            'high': {
                                'range': null,
                                'rated': null,
                                'vehicle': null
                            }
                        },
                        'speed90': 125,
                        'speed120': 154,
                        'winterMultiplicator': 1.4
                    },
                    'price': null,
                    'leasing': {
                        'price': 5699,
                        'wheels': 419,
                        'insurance': 680,
                        'url': 'https://tesla3.dealerpad.net/se/tesla3?session=62cf3f8319f3455cb5594dd03f38126e&make=Tesla&model=Model%203&version=Standard%20RWD%20Plus'
                    },
                    'tax': 315,
                    'service': 0
                }, {
                    'id': 'eedaf5a0-aee2-bed7-5222-7f3c8207ae6d',
                    'name': 'Tesla Model S Long Range',
                    'make': 'Tesla',
                    'model': 'Model',
                    'version': 'S Long Range',
                    'tags': [],
                    'img': null,
                    'color': '#666666',
                    'driven': false,
                    'battery': {
                        'real': 95,
                        'useable': 90
                    },
                    'charging': {
                        'max': 250,
                        'curves': []
                    },
                    'consumption': {
                        'real': {
                            'range': 555,
                            'vehicle': 162
                        },
                        'estimation': {
                            'mild': {
                                'city': 114,
                                'highway': 171,
                                'combined': 141
                            },
                            'cold': {
                                'city': 173,
                                'highway': 225,
                                'combined': 196
                            }
                        },
                        'wltp': {
                            'low': {
                                'range': null,
                                'rated': null,
                                'vehicle': null
                            },
                            'high': {
                                'range': null,
                                'rated': null,
                                'vehicle': null
                            }
                        },
                        'speed90': 141,
                        'speed120': 171,
                        'winterMultiplicator': 1.3900709219858156
                    },
                    'price': null,
                    'leasing': {
                        'price': null,
                        'wheels': null,
                        'insurance': null
                    },
                    'tax': 315,
                    'service': 0
                }, {
                    'id': '3a3441c1-45ca-4a42-e22d-7d54b74b5ca7',
                    'name': 'Fiat 500e Hatchback 42 kWh',
                    'make': 'Fiat',
                    'model': '500e',
                    'version': 'Hatchback 42 kWh',
                    'tags': [],
                    'img': null,
                    'color': '#666666',
                    'driven': false,
                    'battery': {
                        'real': 42,
                        'usable': 37.3
                    },
                    'charging': {
                        'max': 85,
                        'curves': [{
                            source: 'Elbilen',
                            charger: {
                                max: null,
                                where: 'Ionity',
                                temperature: 3.0
                            },
                            data: [{
                                time: 0,
                                power: 50,
                                percentage: 10
                            }, {
                                time: null,
                                power: 55,
                                percentage: 15
                            }, {
                                time: null,
                                power: 62,
                                percentage: 20
                            }, {
                                time: null,
                                power: 62,
                                percentage: 30
                            }, {
                                time: 11,
                                power: 63,
                                percentage: 40
                            }, {
                                time: null,
                                power: 63,
                                percentage: 47
                            }, {
                                time: null,
                                power: 62,
                                percentage: 60
                            }, {
                                time: null,
                                power: 63,
                                percentage: 70
                            }, {
                                time: null,
                                power: 46,
                                percentage: 75
                            }, {
                                time: null,
                                power: 42,
                                percentage: 80
                            }, {
                                time: 31,
                                power: 13,
                                percentage: 85
                            }, {
                                time: null,
                                power: 12,
                                percentage: 90
                            }]
                        }]
                    },
                    'consumption': {
                        'real': {
                            'range': 250,
                            'vehicle': 149
                        },
                        'estimation': {
                            'mild': {
                                'city': 99,
                                'highway': 162,
                                'combined': 129
                            },
                            'cold': {
                                'city': 155,
                                'highway': 213,
                                'combined': 182
                            }
                        },
                        'wltp': {
                            'low': {
                                'range': null,
                                'rated': null,
                                'vehicle': null
                            },
                            'high': {
                                'range': null,
                                'rated': null,
                                'vehicle': null
                            }
                        },
                        'speed90': 129,
                        'speed120': 162,
                        'winterMultiplicator': 1.4108527131782946
                    },
                    'price': null,
                    'leasing': {
                        'price': null,
                        'wheels': null,
                        'insurance': null
                    },
                    'tax': 315,
                    'service': 0
                }, {
                    'id': '30d109d9-0d20-7377-f00c-b74f140c50da',
                    'name': 'Peugeot e-208',
                    'make': 'Peugeot',
                    'model': 'e-208',
                    'version': '',
                    'tags': [],
                    'img': null,
                    'color': '#666666',
                    'driven': false,
                    'battery': {
                        'real': 50,
                        'usable': 45
                    },
                    'charging': {
                        'max': 99,
                        'curves': [{
                            source: 'Vi Bilägare',
                            charger: {
                                max: null,
                                where: 'Ionity',
                                temperature: null
                            },
                            data: [{
                                time: 0,
                                power: 100,
                                percentage: 10
                            }, {
                                time: null,
                                power: 90,
                                percentage: 15
                            }, {
                                time: null,
                                power: 90,
                                percentage: 18
                            }, {
                                time: null,
                                power: 75,
                                percentage: 22
                            }, {
                                time: 12,
                                power: 75,
                                percentage: 36
                            }, {
                                time: null,
                                power: 75,
                                percentage: 50
                            }, {
                                time: null,
                                power: 50,
                                percentage: 54
                            }, {
                                time: null,
                                power: 50,
                                percentage: 70
                            }, {
                                time: null,
                                power: 28,
                                percentage: 74
                            }, {
                                time: 30,
                                power: 28,
                                percentage: 78
                            }, {
                                time: null,
                                power: 28,
                                percentage: 86
                            }, {
                                time: null,
                                power: 10,
                                percentage: 90
                            }]
                        }]
                    },
                    'consumption': {
                        'real': {
                            'range': 275,
                            'vehicle': 164
                        },
                        'estimation': {
                            'mild': {
                                'city': 108,
                                'highway': 176,
                                'combined': 141
                            },
                            'cold': {
                                'city': 167,
                                'highway': 231,
                                'combined': 196
                            }
                        },
                        'wltp': {
                            'low': {
                                'range': null,
                                'rated': null,
                                'vehicle': null
                            },
                            'high': {
                                'range': null,
                                'rated': null,
                                'vehicle': null
                            }
                        },
                        'speed90': 141,
                        'speed120': 176,
                        'winterMultiplicator': 1.3900709219858156
                    },
                    'price': null,
                    'leasing': {
                        'price': null,
                        'wheels': null,
                        'insurance': null
                    },
                    'tax': 315,
                    'service': 0
                }]
            },
            mutations: {
                set(state, list) {
                    state.list = list
                },
                update(state, item) {
                    const i = state.list.findIndex(c => c.id === item.id)
                    if (i !== -1) {
                        state.list.splice(i, 1, item)
                    } else {
                        // console.error('Something went wrong with cars/update')
                    }
                },
                add(state, item) {
                    state.list.splice(state.list.length, 0, item)
                },
                reset(state) {
                    state.list = []
                },
                list(state, list) {
                    state.list = list
                }
            },
            getters: {
                list: state => {
                    return state.list // .sort((a, b) => a.compare().localeCompare(b.compare()))
                },
                get: state => id => {
                    return state.list.find(c => c.id === id)
                }
            },
            actions: {
                async fetch({ commit, state, rootState }) {
                    /* if (rootState.isInactive) {
                        return
                    } */
                    commit('setLoading', true, { root: true })
                    let result = await cars.list()
                    /* Check if we need to update list */
                    if (result && JSON.stringify(result) !== JSON.stringify(state.list)) {
                        commit('set', result)
                    }
                    commit('setLoading', false, { root: true })
                },
                async update({ commit }, { id, model }) {
                    commit('setLoading', true, { root: true })
                    const result = await cars.update(id, model)
                    if (result.exception) {
                        const updatedItem = await cars.fetch(id)
                        commit('update', updatedItem)
                    } else {
                        commit('update', result)
                    }
                    commit('setLoading', false, { root: true })
                    return result
                },
                async add({ commit }, item) {
                    commit('setLoading', true, { root: true })
                    const result = await cars.add(item)
                    if (result) {
                        commit('add', result)
                    }
                    commit('setLoading', false, { root: true })
                    return result
                }
            }
        }
    },
    plugins: [createPersistedState()]
})
