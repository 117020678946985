<template>
    <div>
        <Chart-Bar
            v-if="data"
            :telemetry="filtered"
            :data="{
                suffix: 'N/A',
                labels: ['WLTP', 'Estimerat medel', 'Estimerat Stad', 'Estimerad motorväg', 'Estimerat medel kallt'],
                options: {
                    showLegend: false,
                    indexAxis: 'y',
                    elements: {
                        bar: {
                            borderWidth: 2,
                        }
                    },
                    plugins: {
                        legend: {
                            position: 'right'
                        },
                        title: {
                            display: true,
                            text: ''
                        }
                    },
                    scales: {
                        xAxes: [{
                            min: 0,
                            ticks: {
                                beginAtZero: true
                            }
                        }]
                    },
                    tooltips: {
                        callbacks: {
                            title: (tooltipItems, data) => {
                                return ''
                            },
                            label: (tooltipItems, data) => {
                                // console.log(data)
                                const value = !tooltipItems.value || tooltipItems.value === 'NaN' ? '-' : tooltipItems.value + ' mil'
                                return data.datasets[tooltipItems.datasetIndex].label + ': ' + value
                            }
                        }
                    }
                }
            }"
            style="height: 800px;"
        />
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
// import moment from 'moment'
export default {
    props: {
        car: {
            type: Object,
            required: true
        },
        filter: {
            type: Array,
            required: false,
            default: () => {
                return []
            }
        }
    },
    data: () => ({
        data: null,
        dark: true
    }),
    components: {},
    async mounted() {
        await this.fetch()
    },
    computed: {
        ...mapGetters({
            list: 'cars/list'
        }),
        colors() {
            return {
                grid: this.dark ? 'rgba(25, 25, 25, .1)' : 'rgba(255, 255, 255, 0.2)',
                font: this.dark ? 'rgba(25, 25, 25, .5)' : 'rgba(255, 255, 255, 0.6)',
                point: {
                    border: this.dark ? 'rgba(25, 25, 25, 0.2)' : 'rgba(255, 255, 255, 0.2)',
                    borderHover: this.dark ? 'rgba(25, 25, 25, 0.2)' : 'rgba(255, 255, 255, 0.2)'
                }
            }
        },
        filtered() {
            if (this.filter && this.filter.length > 0) {
                // console.log(this.data.filter(d => this.filter.includes(d.label)))
                const c = this.data.filter(d => this.filter.includes(d.label))
                return c
            }
            return this.data
        }
    },
    methods: {
        async fetch() {
            const data = []

            const currentCar = this.list.find(c => c.id === this.$route.params.id)
            this.list.forEach(car => {
                data.push({
                    label: car.model,
                    data: [this.getConsumption(car, 'wltp.low.vehicle'), this.getConsumption(car, 'estimation.mild.combined'), this.getConsumption(car, 'estimation.mild.city'), this.getConsumption(car, 'estimation.mild.highway'), this.getConsumption(car, 'estimation.cold.combined')],
                    borderColor: currentCar.id === car.id ? '#00bb00' : '#ddeedd',
                    backgroundColor: currentCar.id === car.id ? '#00bb00' : '#ddeedd'
                })
            })

            this.data = data
        },
        getConsumption(car, columnPath) {
            const value = this.getObjectFromDotString(car.consumption, columnPath)
            return parseFloat((car.battery.usable / value * 100).toFixed(1))
        },
        getObjectFromDotString(object, columnPath) {
            const value = columnPath.split('.').reduce((o, i) => o[i], object)
            if (!value) {
                return null
            }
            return value
        }
    }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../../assets/sass/main.scss";
</style>
