<template>
    <v-app class="bg">
        <v-navigation-drawer
            class="mt-2 drawer"
            persistent
            floating
            clipped
            v-model="drawer"
            enable-resize-watcher fixed
            app>
            <Navigation-Menu class="mt-0"/>
        </v-navigation-drawer>

        <v-app-bar
            app
            flat
            color="#ECF1F5"
        >
            <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
            <v-toolbar-title>Elbilskalkyl ⚡️</v-toolbar-title>
            <v-spacer></v-spacer>
        </v-app-bar>
        <v-main class="main">
            <transition name="fade" mode="out-in">
                <router-view />
            </transition>
        </v-main>
        <v-footer
            flat
            color="transparent">
            <span>&copy; elbilskalkyl.se | {{ git.hash }}</span>
            <span class="ml-12">&nbsp;&nbsp;&nbsp;<a href="https://www.facebook.com/elbilskalkyl/">Facebook</a></span>
        </v-footer>
        <div class="information noselect" @click="infoDialog = true">
            <h5>Information</h5>
        </div>
        <InfoDialog v-if="infoDialog" @closeDialog="infoDialog = false" />
    </v-app>
</template>

<script>
export default {
    name: 'App',
    data: () => ({
        drawer: true,
        fixed: false,
        git: {
            hash: null,
            tag: null
        },
        infoDialog: false
    }),
    async mounted() {
        this.git.hash = process.env.VUE_APP_GIT_HASH
        this.git.tag = process.env.VUE_APP_GIT_TAG.replace('refs/tags/', '')
        await this.fetch()
    },
    methods: {
        async fetch() {
            if (this.$store.getters['cars/list'].length === 0) {
                await this.$store.dispatch('cars/fetch', null)
            }
            await this.$store.dispatch('cars/fetch', null)
        }
    }
}
</script>
<style lang="scss">
@import "./assets/sass/main.scss";

.bg {
background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.com/svgjs' width='1920' height='1080' preserveAspectRatio='none' viewBox='0 0 1920 1080'%3e%3cg mask='url(%26quot%3b%23SvgjsMask1018%26quot%3b)' fill='none'%3e%3crect width='1920' height='1080' x='0' y='0' fill='rgba(236%2c 241%2c 245%2c 1)'%3e%3c/rect%3e%3cpath d='M1920 0L1271.9299999999998 0L1920 424.96z' fill='rgba(255%2c 255%2c 255%2c .1)'%3e%3c/path%3e%3cpath d='M1271.9299999999998 0L1920 424.96L1920 641.86L1204.8999999999999 0z' fill='rgba(255%2c 255%2c 255%2c .075)'%3e%3c/path%3e%3cpath d='M1204.9 0L1920 641.86L1920 828.24L545.44 0z' fill='rgba(255%2c 255%2c 255%2c .05)'%3e%3c/path%3e%3cpath d='M545.44 0L1920 828.24L1920 853.17L213.93000000000006 0z' fill='rgba(255%2c 255%2c 255%2c .025)'%3e%3c/path%3e%3cpath d='M0 1080L59.82 1080L0 807.88z' fill='rgba(0%2c 0%2c 0%2c .1)'%3e%3c/path%3e%3cpath d='M0 807.88L59.82 1080L1029.6299999999999 1080L0 478.61z' fill='rgba(0%2c 0%2c 0%2c .075)'%3e%3c/path%3e%3cpath d='M0 478.61L1029.6299999999999 1080L1033.53 1080L0 224.28z' fill='rgba(0%2c 0%2c 0%2c .05)'%3e%3c/path%3e%3cpath d='M0 224.27999999999997L1033.53 1080L1257.19 1080L0 181.31999999999996z' fill='rgba(0%2c 0%2c 0%2c .025)'%3e%3c/path%3e%3c/g%3e%3cdefs%3e%3cmask id='SvgjsMask1018'%3e%3crect width='1920' height='1080' fill='white'%3e%3c/rect%3e%3c/mask%3e%3c/defs%3e%3c/svg%3e") !important;
    background-attachment: fixed !important;
    background-size: cover !important;
    background-repeat: repeat !important;
    background-position: center center !important;
}

.theme--light.v-navigation-drawer {
    background-color: transparent !important;
    border-right: 1px solid rgba(135, 154, 161, 0.24);
}

.main {
    margin-left: 16px;
    margin-top: 72px;
    padding-top: 0 !important;
    overflow-y: auto;
}

.v-toolbar {
    z-index: 98 !important;
}

.information {
  bottom: 8px;
  right: 12px;
  position: absolute;
  letter-spacing: 2px;
  cursor: pointer;

    h5 {
        font-weight: 300;
        font-size: .675em;
        color: #7b8591;
    }
}

@media only screen and (max-width: 600px) {
    .main {
        padding-top: 46px !important;
        margin: 0;
    }
}

.versions {
    color: $main_4_light !important;
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  transition: opacity .1s ease-in;
}

.fade-leave-active {
  transition: opacity .1s ease-out;
  opacity: 0;
}

@media only screen and (max-width: 1263px) {
    .bg .drawer {
        background-color: rgb(236, 241, 245) !important;
    }
}
</style>
