<template>
    <div>
        <Chart-Scatter
            v-if="data"
            :telemetry="filtered"
            :data="{
                type: 'scatter',
                options: {
                    legend: {
                        display: false
                    },
                    elements: {
                        line: {
                            tension: 0.09
                        }
                    },
                    tooltips: {
                        enabled: true,
                        mode: 'single',
                        callbacks: {
                            label: (tooltipItems, data) => {
                                return tooltipItems.yLabel + ' kW';
                            }
                        }
                    },
                    scales: {
                        xAxes: [{
                            distribution: 'linear',
                            position: 'bottom',
                            gridLines: {
                                color: colors.grid
                            },
                            ticks: {
                                fontColor: colors.font,
                                callback: (tick, index, ticks) => {
                                    return tick + '%'
                                },
                                suggestedMin: 0,
                                suggestedMax: 100
                            }
                        }],
                        yAxes: [{
                            id: 'y1',
                            labelString: 'y1',
                            title: {
                                text: 'T1',
                                display: true,
                                fontColor: colors.font
                            },
                            display: true,
                            ticks: {
                                beginAtZero: false,
                                fontColor: colors.font,
                                precision: 1,
                                maxTicksLimit: 10,
                                callback: (tick, index, ticks) => {
                                    return tick + ' kW'
                                }
                            },
                            gridLines: {
                                color: colors.grid
                            }
                        }]
                    }
                }
            }"
            style="height: 30vh;"
        />
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    props: {
        car: {
            type: Object,
            required: true
        },
        filter: {
            type: Array,
            required: false,
            default: () => {
                return []
            }
        }
    },
    data: () => ({
        data: null,
        dark: true
    }),
    components: {},
    async mounted() {
        await this.fetch()
    },
    computed: {
        ...mapGetters({
            list: 'cars/list'
        }),
        colors() {
            return {
                grid: this.dark ? 'rgba(25, 25, 25, .1)' : 'rgba(255, 255, 255, 0.2)',
                font: this.dark ? 'rgba(25, 25, 25, .5)' : 'rgba(255, 255, 255, 0.6)',
                point: {
                    border: this.dark ? 'rgba(25, 25, 25, 0.2)' : 'rgba(255, 255, 255, 0.2)',
                    borderHover: this.dark ? 'rgba(25, 25, 25, 0.2)' : 'rgba(255, 255, 255, 0.2)'
                }
            }
        },
        filtered() {
            if (this.filter && this.filter.length > 0) {
                const c = this.data.filter(d => this.filter.includes(d.label))
                return c
            }
            return this.data
        }
    },
    methods: {
        async fetch() {
            const data = []
            const currentCar = this.list.find(c => c.id === this.$route.params.id)
            this.list.filter(car => car.charging.curves && car.charging.curves.length > 0).forEach(car => {
                const curve = {
                    label: `${car.model}`,
                    data: car.charging.curves[0].data.map(item => {
                        return {
                            x: item.percentage,
                            y: item.power
                        }
                    }),
                    borderColor: currentCar.id === car.id ? '#bb0000' : '#eedddd',
                    backgroundColor: 'rgba(0,0,0,0)',
                    showLine: true
                }
                data.push(curve)
            })
            this.data = data
        }
    }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../../assets/sass/main.scss";
</style>
