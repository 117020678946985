<template>
    <DataTable
        :data="{ result: cars }"
        :showSearch="true"
        :columns="[{
            name: 'make',
            title: translate('cars.make')
        }, {
            name: 'model',
            title: translate('cars.model')
        }, {
            name: 'version',
            title: translate('cars.version')
        }, {
            name: 'max',
            title: translate('cars.chargingMax')
        }, {
            name: 'range',
            title: translate('cars.range')
        }, {
            name: 'curve',
            title: translate('cars.hasCurve')
        }]"
        @onSelectRow="onSelectRow"
    />
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    data: () => ({}),
    computed: {
        ...mapGetters({
            list: 'cars/list'
        }),
        cars: {
            get() {
                const cs = []
                this.list.forEach(car => {
                    const c = { ...car }
                    c.price = car.price ? this.numberWithSpaces(car.price) + ' kr' : ''
                    c.max = car.charging.max + ' kW'
                    c.range = car.consumption.real.range + ' km'
                    c.curve = c.charging.curves && c.charging.curves.length > 0 ? 'Tillgänglig' : 'Saknas'
                    cs.push(c)
                })
                return cs
            },
            set(value) {
                return this.$store.commit('cars/set', value)
            }
        }
    },
    methods: {
        onSelectRow(item) {
            this.$router.push({
                path: `/cars/${item.id}`
            })
        },
        numberWithSpaces(x) {
            if (!x && x !== 0) {
                return 'N/A'
            }
            var parts = x.toString().split('.')
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
            parts[1] = parts[1] ? parts[1].substring(0, 2) : null

            return parts[1] ? parts.join(',') : parts[0]
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/main.scss";

.vuetifyTable {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}
.v-data-table-header {
    cursor: pointer;
    color: $table_header_color !important;
    background: $secondary_1_light !important;
    th {
        font-weight: $font-weight-normal !important;
        font-size: 16px !important;
        color: rgba(0,0,0,.87) !important;
    }
}
</style>
