<template>
    <div :class="{ missing: data == null }">
        <FormulateInput
            v-if="edit"
            type="text"
            inputmode="numeric"
            pattern="[0-9]+([\.,][0-9]+)?"
            step="0.1"
            :label="label"
            :placeholder="suffix"
            v-model="data"
        />
        <template v-else>
            {{ numberWithSpaces(data / divider) }} <template>{{ suffix }}</template>
        </template>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: Number,
            required: true
        },
        edit: {
            type: Boolean,
            required: false,
            default: false
        },
        label: {
            type: String,
            required: false,
            default: null
        },
        suffix: {
            type: String,
            required: false,
            default: null
        },
        divider: {
            type: Number,
            required: false,
            default: 1
        }
    },
    data: () => ({
        data: null
    }),
    created() {
        this.data = this.value
    },
    methods: {
        numberWithSpaces(x) {
            if (!x && x !== 0) {
                return 'N/A'
            }
            var parts = x.toString().split('.')
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
            parts[1] = parts[1] ? parts[1].substring(0, 2) : null

            return parts[1] ? parts.join(',') : parts[0]
        }
    },
    watch: {
        data: {
            handler(to, from) {
                if (to === '') {
                    to = '0'
                }
                if (typeof to === 'string') {
                    this.$emit('input', parseFloat(to.replace(',', '.')))
                } else {
                    this.$emit('input', to)
                }
            }
        },
        value: {
            handler(to, from) {
                this.data = (to + '').replace('.', ',')
            }
        }
    }
}
</script>
<style scoped lang="scss">
@import "@/assets/sass/main.scss";
@import "@/assets/sass/formulate.scss";
.missing {
    background: $secondary_3_light;
}
</style>
