import axios from 'axios'

const URI = 'https://elbilskalkyl.azurewebsites.net/api'
// const URI = process.env.VUE_APP_API_URL

export default class ApiCaller {
    run(path, payload) {
        return new Promise((resolve, reject) => {
            const headers = this.getHeaders('POST', path)
            axios
                .post(URI + path, payload, {
                    headers: headers
                })
                .then(response => {
                    resolve(response)
                })
                .catch(e => {
                    // console.log('exception', e)
                    reject(e)
                })
        })
    }

    getHeaders(verb, path) {
        const result = {}
        result['Content-Type'] = 'application/json'
        result.accept = 'application/json'

        return result
    }
}
