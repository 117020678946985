var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.data)?_c('Chart-Scatter',{staticStyle:{"height":"30vh"},attrs:{"telemetry":_vm.filtered,"data":{
            type: 'scatter',
            options: {
                legend: {
                    display: false
                },
                elements: {
                    line: {
                        tension: 0.09
                    }
                },
                tooltips: {
                    enabled: true,
                    mode: 'single',
                    callbacks: {
                        label: function (tooltipItems, data) {
                            return tooltipItems.yLabel + ' kW';
                        }
                    }
                },
                scales: {
                    xAxes: [{
                        distribution: 'linear',
                        position: 'bottom',
                        gridLines: {
                            color: _vm.colors.grid
                        },
                        ticks: {
                            fontColor: _vm.colors.font,
                            callback: function (tick, index, ticks) {
                                return tick + '%'
                            },
                            suggestedMin: 0,
                            suggestedMax: 100
                        }
                    }],
                    yAxes: [{
                        id: 'y1',
                        labelString: 'y1',
                        title: {
                            text: 'T1',
                            display: true,
                            fontColor: _vm.colors.font
                        },
                        display: true,
                        ticks: {
                            beginAtZero: false,
                            fontColor: _vm.colors.font,
                            precision: 1,
                            maxTicksLimit: 10,
                            callback: function (tick, index, ticks) {
                                return tick + ' kW'
                            }
                        },
                        gridLines: {
                            color: _vm.colors.grid
                        }
                    }]
                }
            }
        }}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }