<template>
    <div class="mt-4">
        <p>Ranka bilarna genom att dra korten i den ordning du gillar dem</p>
        <router-link
            v-if="auth.checkAuth()"
            class="menuItem"
            to="cars/add"
            tag="button"
        >
            Lägg till elbil
        </router-link>
        <v-row>
            <draggable v-model="cars" v-if="cars" class="w100">
                <transition-group>
                    <v-col v-for="item in cars"
                        :key="`d1${item.id}`"
                        class="car pa-0 mb-2"
                        cols="12"
                    >
                        <v-card :key="`d1${item.id}`"
                            class="pa-4"
                            flat
                            :class="auth.checkAuth() ? { notDriven: !item.driven } : null"
                            @click="onSelectRow(item)"
                        >
                            <div class="d-flex flex-no-wrap justify-space-between" :key="`d1${item.id}`">
                                <div>
                                    <v-card-title
                                        class="text-h5 pa-0"
                                    >
                                        {{ item.make }} {{ item.model }}
                                    </v-card-title>

                                    <v-card-subtitle class="pt-4 pl-1">
                                        {{ item.make }} {{ item.model }} {{ item.version }}
                                    </v-card-subtitle>

                                    <v-layout wrap class="infoBox">
                                        <v-flex xs12>
                                            <table>
                                                <tr>
                                                    <td>Räckvidd (WLTP)</td>
                                                    <td>{{ item.consumption.real.range }} km</td>
                                                </tr>
                                                <tr>
                                                    <td>Max laddstyrka</td>
                                                    <td>{{ item.charging.max }} kW</td>
                                                </tr>
                                                <tr>
                                                    <td>Förbrukning (WLTP)</td>
                                                    <td>{{ item.consumption.wltp.low ? item.consumption.wltp.low.rated : item.consumption.wltp }} Wh/km</td>
                                                </tr>
                                                <tr>
                                                    <td>Batteri</td>
                                                    <td>{{ item.battery.usable }}  kWh</td>
                                                </tr>
                                                <!-- Fält användaren får mata in själv -->
                                                <tr>
                                                    <td>Pris</td>
                                                    <td>{{ item.price }}</td>
                                                </tr>
                                                <tr>
                                                    <td>Privatleasing 3 år</td>
                                                    <td>{{ item.leasing.price }}</td>
                                                </tr>
                                            </table>
                                        </v-flex>
                                    </v-layout>
                                    <v-card-actions v-if="auth.checkAuth()">
                                        <v-btn
                                            v-if="item.driven"
                                            class="mt-5 pa-4"
                                            outlined
                                            rounded
                                            small
                                            @click.stop="setDriven(item, !item.driven)"
                                        >
                                            Jag har inte kört bilen
                                        </v-btn>
                                        <v-btn
                                            v-else
                                            class="ml-2 mt-5"
                                            outlined
                                            rounded
                                            small
                                            @click.stop="setDriven(item, !item.driven)"
                                        >
                                            Jag har kört bilen
                                        </v-btn>
                                    </v-card-actions>
                                </div>
                                <v-avatar
                                    class="avatarImage"
                                    size="200"
                                    tile
                                >
                                    <v-img :src="`cars/${item.img}`"></v-img>
                                </v-avatar>
                            </div>
                        </v-card>
                    </v-col>
                </transition-group>
            </draggable>
        </v-row>
    </div>
</template>

<script>
import Draggable from 'vuedraggable'
import { mapGetters } from 'vuex'
import Auth from '@/helpers/auth'
const auth = new Auth()
export default {
    data: () => ({
        auth: auth,
        edit: false
    }),
    components: { Draggable },
    mounted() {
        this.parseQueryString()
    },
    computed: {
        ...mapGetters({
            list: 'cars/list'
        }),
        cars: {
            get() {
                return this.list
            },
            set(value) {
                return this.$store.commit('cars/set', value)
            }
        }
    },
    methods: {
        onSelectRow(item) {
            this.$router.push({
                path: `/cars/${item.id}`
            })
        },
        setDriven(item, driven) {
            this.$set(item, 'driven', driven)
            this.$store.dispatch('cars/update', { id: item.id, model: item })
        },
        addParamsToLocation() {
            this.edit = true
            const params = {
                rank: JSON.stringify(this.cars.map(car => {
                    return car.id
                }))
            }
            const query = { ...this.$route.query, rank: params.rank }
            if (query.rank === this.$route.query.rank) {
                return
            }
            this.$router.replace({ query })
            localStorage.setItem('rank', query.rank)
            this.edit = false
        },
        parseQueryString() {
            let rank = null
            if (this.$route.query.rank) {
                // Restore
                rank = this.$route.query.rank
            } else {
                rank = localStorage.getItem('rank')
            }

            if (!rank) {
                return
            }
            rank = JSON.parse(rank)
            const first = []
            const last = []
            rank.forEach((c, i) => {
                const found = this.cars.find(car => car.id === c)
                if (found) {
                    first.push(found)
                } else {
                    last.push(found)
                }
            })
            this.cars = [...first, ...last]
        }
    },
    watch: {
        cars: {
            handler(to, from) {
                this.addParamsToLocation()
            },
            deep: true
        },
        '$route': {
            handler(to, from) {
                if (this.edit) {
                    return
                }
                this.parseQueryString()
            }
        }
    }
}
</script>
<style scoped lang="scss">
@import "@/assets/sass/main.scss";

.v-card {
  border: 2px solid #e2e2e2;
}

.notDriven {
    opacity: 0.5;
}

.infoBox {
    width: 340px;
    border-right: 1px solid #e2e2e2 !important;
}

.avatarImage {
    align-content: center;
    margin-top: 42px;
}

.w100 {
    width: 100%;
    max-width: 720px;
}

table {
  border-spacing: 4px;
}

</style>
