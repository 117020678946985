<template>
    <div>
        <InputCell
            :edit="edit"
            type="number"
            :label="label"
            :placeholder="suffix"
            :suffix="suffix"
            v-model.number="data"
        />
        <template v-if="edit">
            <button
                v-if="car.consumption.real.vehicle"
                @click="data = parseFloat((car.consumption.real.vehicle).toFixed(2))"
            >
                EVDB Riktig förbrukning
            </button><br />
            <button
                v-if="car.consumption.estimation.mild.combined"
                @click="data = parseFloat((car.consumption.estimation.mild.combined).toFixed(2))"
            >
                EVDB Milt väder Kombinerat
            </button><br />
            <button
                v-if="car.consumption.estimation.cold.combined"
                @click="data = parseFloat((car.consumption.estimation.cold.combined).toFixed(2))"
            >
                EVDB Kallt väder Kombinerat
            </button><br />
            <button
                v-if="car.consumption.wltp.low.vehicle"
                @click="data = parseFloat((car.consumption.wltp.low.vehicle).toFixed(2))"
            >
                WLTP Låg förbrukning
            </button><br />
            <button
                v-if="car.consumption.wltp.high.vehicle"
                @click="data = parseFloat((car.consumption.wltp.high.vehicle).toFixed(2))"
            >
                WLTP Hög förbrukning
            </button><br />
        </template>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: Number,
            required: true
        },
        car: {
            type: Object,
            required: true
        },
        edit: {
            type: Boolean,
            required: false,
            default: false
        },
        label: {
            type: String,
            required: false,
            default: null
        },
        suffix: {
            type: String,
            required: false,
            default: null
        },
        divider: {
            type: Number,
            required: false,
            default: 1
        }
    },
    data: () => ({
        data: null
    }),
    created() {
        this.data = this.value
    },
    methods: {},
    watch: {
        data: {
            handler(to, from) {
                this.$emit('input', to)
            }
        }
    }
}
</script>
