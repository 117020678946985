<template>
    <div class="chart-container" :class="classes">
        <div v-if="noData" class="center noData">
            <h1 class="grey--text">{{ translate('common.noData') }}</h1>
        </div>
        <!-- Canvas for Chart.js -->
        <canvas v-else :id="'canvas_' + id"></canvas>
    </div>
</template>

<script>
import Chart from 'chart.js'
import { Guid } from '@/helpers'
// import chartColors from './colors'
const guid = new Guid()
// const colors = chartColors()

export default {
    props: {
        telemetry: {
            type: Array,
            required: true
        },
        data: {
            type: Object,
            required: true
        },
        mixins: {
            type: Array,
            required: false,
            default: null
        },
        dark: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    data: () => ({
        chart: null,
        id: guid.generate(),
        weekLabels: null,
        yearLabels: null,
        dataToPlot: [],
        toggle_time: 1,
        configurationHash: null,
        noData: false
    }),
    mounted() {
        this.drawChart(this.telemetry)
    },
    computed: {
        classes() {
            if (!this.data.configuration) {
                return
            }
            let result = {
                theme: true
            }
            result[this.data.configuration.theme] = true
            return result
        },
        colors() {
            return {
                grid: this.dark ? 'rgba(25, 25, 25, .1)' : 'rgba(255, 255, 255, 0.2)',
                font: this.dark ? 'rgba(25, 25, 25, .5)' : 'rgba(255, 255, 255, 0.6)',
                point: {
                    border: this.dark ? 'rgba(25, 25, 25, 0.2)' : 'rgba(255, 255, 255, 0.2)',
                    borderHover: this.dark ? 'rgba(25, 25, 25, 0.2)' : 'rgba(255, 255, 255, 0.2)'
                }
            }
        }
    },
    methods: {
        toFixed(input) {
            if (input) {
                return input.toFixed(this.data.configuration.fractionDigits)
            } else {
                return 0
            }
        },
        drawChart(dataToPlot, noAnimation) {
            if (!dataToPlot || dataToPlot.length === 0 || dataToPlot[0].data.length === 0) {
                this.noData = true
                return
            }
            this.noData = false

            let data = {
                labels: this.data.labels,
                datasets: dataToPlot.map((r, i) => {
                    return r
                })
            }

            if (this.chart == null) {
                this.createChart(data)
            }

            const showLegend = this.data.options.legend != null ? this.data.options.legend.display : true
            this.chart.options.legend.display = showLegend
            this.chart.options.maintainAspectRatio = false

            this.chart.data = data
            if (noAnimation) {
                this.chart.update('none')
            } else {
                this.chart.update()
            }
        },
        createChart(data) {
            var canvas = document.getElementById('canvas_' + this.id)
            // Apply multiply blend when drawing datasets
            var multiply = {
                beforeDatasetsDraw(chart, options, el) {
                    chart.ctx.globalCompositeOperation = 'multiply'
                },
                afterDatasetsDraw(chart, options) {
                    chart.ctx.globalCompositeOperation = 'source-over'
                }
            }
            const showLegend = this.data.options.showLegend ? this.data.options.showLegend : true
            let options = { ...this.data.options }
            options.responsive = true
            options.legend = {
                display: showLegend
            }

            var config = {
                type: 'scatter',
                data: data,
                options: options,
                plugins: [multiply]
            }

            this.chart = new Chart(canvas, config) // eslint-disable-line no-undef
        }
    },
    watch: {
        telemetry: {
            handler(to, from) {
                this.drawChart(this.telemetry)
            },
            deep: false
        },
        'data.configuration': {
            handler(to, from) {
                this.drawChart(this.telemetry, false)
                // this.chart.update('none')
            },
            deep: true
        }
    }
}
</script>

<style scoped lang="scss">
.chart {
    color: #eee;
    &.dark {
        color: darkslategrey;
    }
}

.legends {
  padding-top: 20px;
  overflow: hidden;
}

.legend {
  display: block;
  width: 8px;
  height: 8px;
  margin-top: 15px;
  margin-bottom: 15px;
  border-radius: 50%;
}

@keyframes bg
{
  0% {
    background-position: 0% 50%
}
50% {
    background-position: 100% 50%
}
100% {
    background-position: 0% 50%
}
}

</style>
