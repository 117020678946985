<template>
    <div>
        <FormulateInput
            class="slider"
            v-if="edit"
            type="range"
            min="0"
            max="100"
            :label="label"
            :placeholder="suffix"
            v-model.number="data"
        />
        <InputCell
            :edit="false"
            type="number"
            :label="label"
            :placeholder="suffix"
            :suffix="suffix"
            v-model.number="data"
            :divider="divider"
        />
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: Number,
            required: true
        },
        edit: {
            type: Boolean,
            required: false,
            default: false
        },
        label: {
            type: String,
            required: false,
            default: null
        },
        suffix: {
            type: String,
            required: false,
            default: null
        },
        divider: {
            type: Number,
            required: false,
            default: 1
        }
    },
    data: () => ({
        data: null
    }),
    created() {
        this.data = this.value
    },
    methods: {
        numberWithSpaces(x) {
            if (!x && x !== 0) {
                return 'N/A'
            }
            var parts = x.toString().split('.')
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
            parts[1] = parts[1] ? parts[1].substring(0, 2) : null

            return parts[1] ? parts.join(',') : parts[0]
        }
    },
    watch: {
        data: {
            handler(to, from) {
                this.$emit('input', to)
            }
        }
    }
}
</script>
<style scoped lang="scss">
@import "@/assets/sass/main.scss";
@import "@/assets/sass/formulate.scss";
$range-handle-size: 32px;
.slider {
// Range Handle
    ::-webkit-slider-thumb {
        appearance: none;
        width: $range-handle-size;
        height: $range-handle-size;
        border-radius: 50%;
        background: $main_3;
        cursor: pointer;
        transition: background .15s ease-in-out;

        &:hover {
            background: $main_1;
        }
    }
}
</style>
