var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.data)?_c('Chart-Bar',{staticStyle:{"height":"800px"},attrs:{"telemetry":_vm.filtered,"data":{
            suffix: 'N/A',
            labels: ['WLTP', 'Estimerat medel', 'Estimerat Stad', 'Estimerad motorväg', 'Estimerat medel kallt'],
            options: {
                showLegend: false,
                indexAxis: 'y',
                elements: {
                    bar: {
                        borderWidth: 2,
                    }
                },
                plugins: {
                    legend: {
                        position: 'right'
                    },
                    title: {
                        display: true,
                        text: ''
                    }
                },
                scales: {
                    xAxes: [{
                        min: 0,
                        ticks: {
                            beginAtZero: true
                        }
                    }]
                },
                tooltips: {
                    callbacks: {
                        title: function (tooltipItems, data) {
                            return ''
                        },
                        label: function (tooltipItems, data) {
                            // console.log(data)
                            var value = !tooltipItems.value || tooltipItems.value === 'NaN' ? '-' : tooltipItems.value + ' mil'
                            return data.datasets[tooltipItems.datasetIndex].label + ': ' + value
                        }
                    }
                }
            }
        }}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }