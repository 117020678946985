<template>
    <div class="home">
        <v-container fluid>
            <v-row align-start>
                <v-col lg="7" cols="12" >
                    <div class="text">
                        <h2>RÄKNA PÅ</h2>
                        <h3>DITT BILÄGANDE</h3>
                        <p>Denna sidan tog vi fram för att hjälpa vänner, kollegor och bekanta med att räkna på elbilars kostnad jämfört med en fossilbil.</p>
                        <p>Informationen du matar in här stannar lokalt på din dator. Detta kommer troligtvis att utökas till en möjlighet att du kan välja att dela din information med andra senare. Vi vill däremot inte samla in någon data och sälja vidare den till någon annan.</p>
                        <v-container>
                            <v-row>
                                <v-col lg="4" cols="12" >
                                    <HomeCard title="Beräkningsark"
                                        paragraph="I denna vy går det att jämföra din nuvarande bil eller en ny fossilbil kontra vad det kostar att privatleasa en elbil."
                                        image="settings"
                                        path="sheet" />
                                </v-col>
                                <v-col lg="4" cols="12" >
                                    <HomeCard title="Appar"
                                        paragraph="Här visas rekommenderade tjänster som förenklar ditt bilägande."
                                        image="navigator"
                                        path="apps" />
                                </v-col>
                                <v-col lg="4" cols="12" >
                                    <HomeCard title="Bilar"
                                        paragraph="Jämför och ranka bilar genom att se pris, maxladdstyrka och räckvidd."
                                        image="order"
                                        path="cars/compare" />
                                </v-col>
                            </v-row>
                        </v-container>
                    </div>
                </v-col>
                <v-col lg="5" cols="12">
                    <img src="../assets/images/animatedCar.svg" />
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>

export default {
    name: 'Home'
}
</script>
<style scoped lang="scss">
@import "@/assets/sass/main.scss";
@import url('https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,600,700,800,900&display=swap');
*
{
  font-family: 'Poppins', sans-serif;
}
.home
{
  position: absolute;
  right: 0;
  width: 100%;
  min-height: calc(100vh - 112px);
  padding: 30px;
  display: flex;
  justify-content: space-between;
  background: $white;
  transition: 0.5s;
  z-index: 2;
}

img {
  max-width: 650px;
  object-fit: contain;
  display:block;
  margin:auto;
}

.text
{
  position: relative;
  z-index: 10;
}

.text h2
{
  font-size: 5em;
  font-weight: 600;
  color: $black;
  line-height: 1em;
  text-transform: uppercase;
}
.text h3
{
  font-size: 4em;
  font-weight: 400;
  color: $black;
  line-height: 1em;
  text-transform: uppercase;
}
.text p
{
  font-size: 1.1em;
  color: $black;
  margin: 20px 0;
  font-weight: 400;
  max-width: 700px;
}

@media (max-width: 1920px)
{
  .home {
    padding: 16px;
  }
  .text h2
  {
    font-size: 3.7em;
  }
  .text h3
  {
    font-size: 2.6em;
  }
}

@media (max-width: 991px)
{
  .home {
    padding: 4px;
  }
  .text h2
  {
    font-size: 3em;
  }
  .text h3
  {
    font-size: 2em;
  }
}

</style>
