var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DataTable',{attrs:{"data":{ result: _vm.cars },"showSearch":true,"columns":[{
        name: 'make',
        title: _vm.translate('cars.make')
    }, {
        name: 'model',
        title: _vm.translate('cars.model')
    }, {
        name: 'version',
        title: _vm.translate('cars.version')
    }, {
        name: 'max',
        title: _vm.translate('cars.chargingMax')
    }, {
        name: 'range',
        title: _vm.translate('cars.range')
    }, {
        name: 'curve',
        title: _vm.translate('cars.hasCurve')
    }]},on:{"onSelectRow":_vm.onSelectRow}})}
var staticRenderFns = []

export { render, staticRenderFns }