import moment from 'moment'
import axios from 'axios'

var LOGIN_URL = process.env.VUE_APP_API_URL
var storage = localStorage
const clientId = '23426982304072658405735'

LOGIN_URL = LOGIN_URL + '/authenticate'

export default class Auth {
    // User object will let us check authentication status
    user = {
        authenticated: false
    }

    // Send a request to the login URL and save the returned JWT
    login(context, creds) {
        return new Promise((resolve, reject) => {
            const body = new URLSearchParams()
            body.append('grant_type', 'password')
            body.append('username', creds.username)
            body.append('password', creds.password)
            body.append('client_id', clientId)

            axios
                .post(LOGIN_URL, body)
                .then(response => { // Called when successful
                    storage.setItem('access_token', response.data.access_token)
                    storage.setItem('refresh_token', response.data.refresh_token)
                    let expiresAt = moment().add(response.data.expires_in, 'seconds').format('YYYY-MM-DD HH:mm:ss')
                    storage.setItem('expires', expiresAt)
                    setTimeout(() => {
                        this.refresh()
                    }, (response.data.expires_in - 1) * 1000)

                    resolve({
                        username: response.data.username,
                        displayName: response.data.displayName,
                        roles: JSON.parse(response.data.roles),
                        authenticated: true
                    })
                })
                .catch(e => {
                    reject(e) // Let the view handling login decide how to deal with the error
                })
        })
    }
    refresh(context) {
        return new Promise((resolve, reject) => {
            const body = new URLSearchParams()
            body.append('grant_type', 'refresh_token')
            body.append('refresh_token', storage.getItem('refresh_token'))
            body.append('client_id', clientId)

            axios
                .post(LOGIN_URL, body)
                .then(response => {
                    // called when successful
                    storage.setItem('access_token', response.data.access_token)
                    storage.setItem('refresh_token', response.data.refresh_token)
                    let expiresAt = moment().add(response.data.expires_in, 'seconds').format('YYYY-MM-DD HH:mm:ss')
                    storage.setItem('expires', expiresAt)

                    setTimeout(() => {
                        this.refresh()
                    }, (response.data.expires_in - 1) * 1000)

                    resolve({
                        authenticated: true
                    })
                })
                .catch(e => {
                    reject(e)
                    // console.error(e.stack)
                })
        })
    }
    // To log out, we just need to remove the token
    logout(store) {
        return new Promise((resolve, reject) => {
            storage.clear()
            this.user.authenticated = false
            store.commit('setLoggedInUser', null)
            store.commit('setPreferences', null)
            store.commit('customers/reset')
            store.commit('devices/reset')
            store.commit('groups/reset')
            store.commit('users/reset')
            store.commit('dashboards/reset')
            // console.log('Logged out')
            resolve()
        })
    }
    checkAuth() {
        var jwt = storage.getItem('access_token')
        if (jwt) {
            this.user.authenticated = true
        } else {
            this.user.authenticated = false
        }
        return this.user.authenticated
    }
    getToken() {
        return storage.getItem('access_token')
    }
    tokenExpiresIn() {
        var expires = storage.getItem('expires')
        var expiresTime = moment(expires)
        let d = new Date(expiresTime)
        let difference = d.getTime() - new Date(moment()).getTime()
        return difference
    }
    async validatePassword(username, password) {
        return new Promise((resolve, reject) => {
            const body = new URLSearchParams()
            body.append('grant_type', 'password')
            body.append('username', username)
            body.append('password', password)
            body.append('client_id', clientId)

            axios
                .post(LOGIN_URL, body)
                .then(response => {
                    resolve({
                        username: response.data.username,
                        displayName: response.data.displayName,
                        roles: JSON.parse(response.data.roles),
                        authenticated: true
                    })
                })
                .catch(e => {
                    reject(e) // Let the view calling for password validation decide how to handle the error
                })
        })
    }
}
