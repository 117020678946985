<template>
    <div>
        <Chart-Line
            v-if="data && telemetry"
            :telemetry="filtered"
            :data="data"
            style="height: 30vh;"
        />
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
export default {
    props: {
        car: {
            type: Object,
            required: true
        },
        filter: {
            type: Array,
            required: false,
            default: () => {
                return []
            }
        }
    },
    data: () => ({
        data: null,
        telemetry: null,
        dark: true
    }),
    components: {},
    async mounted() {
        await this.fetch()
    },
    computed: {
        ...mapGetters({
            list: 'cars/list'
        }),
        colors() {
            return {
                grid: this.dark ? 'rgba(25, 25, 25, .1)' : 'rgba(255, 255, 255, 0.2)',
                font: this.dark ? 'rgba(25, 25, 25, .5)' : 'rgba(255, 255, 255, 0.6)',
                point: {
                    border: this.dark ? 'rgba(25, 25, 25, 0.2)' : 'rgba(255, 255, 255, 0.2)',
                    borderHover: this.dark ? 'rgba(25, 25, 25, 0.2)' : 'rgba(255, 255, 255, 0.2)'
                }
            }
        },
        filtered() {
            if (this.filter && this.filter.length > 0) {
                const c = this.telemetry.filter(d => this.filter.includes(d.label))
                return c
            }
            return this.telemetry
        }
    },
    methods: {
        async fetch() {
            const data = []
            const currentCar = this.list.find(c => c.id === this.$route.params.id)
            this.list.filter(car => car.charging.curves && car.charging.curves.length > 0).forEach(car => {
                const curves = [{
                    path: `${car.model} Laddat`,
                    suffix: 'kWh',
                    yAxisID: 'y1',
                    data: car.charging.curves[0].data.filter(item => item.time != null).map(item => {
                        return {
                            x: moment(0).add(item.time, 'm').toISOString(),
                            y: item.percentage * 0.01 * car.battery.usable
                        }
                    }),
                    type: 'line',
                    color: currentCar.id === car.id ? '#00bb00' : '#ddeedd',
                    label: car.model
                }, {
                    path: `${car.model} Effekt`,
                    suffix: 'kW',
                    yAxisID: 'y2',
                    data: car.charging.curves[0].data.filter(item => item.time != null).map(item => {
                        return {
                            x: moment(0).add(item.time, 'm').toISOString(),
                            y: item.power
                        }
                    }),
                    type: 'line',
                    color: currentCar.id === car.id ? '#bb0000' : '#eedddd',
                    label: car.model
                }]
                data.push(...curves)
            })
            this.telemetry = data

            this.data = {
                configuration: {
                    showLegend: false,
                    suffix: '%',
                    fractionDigits: 1,
                    groupBy: null,
                    setAutomaticMinMax: true,
                    xAxes: [{
                        distribution: 'linear',
                        type: 'time',
                        time: {
                            minUnit: 'minute',
                            displayFormats: {
                                day: 'D/M',
                                hour: 'D/M HH:mm',
                                minute: 'mm'
                            },
                            stepSize: 10
                        },
                        position: 'bottom',
                        maxTicksLimit: 10,
                        gridLines: {
                            color: this.colors.grid
                        },
                        ticks: {
                            fontColor: this.colors.font,
                            maxTicksLimit: 10,
                            callback: (value, index, values) => {
                                return values[index].value / 60000 + ' min'
                            }
                        }
                    }],
                    yAxes: [{
                        id: 'y1',
                        labelString: 'y1',
                        title: {
                            text: 'T1',
                            display: true,
                            fontColor: this.colors.font
                        },
                        display: true,
                        ticks: {
                            beginAtZero: false,
                            fontColor: this.colors.font,
                            precision: 1,
                            maxTicksLimit: 10,
                            callback: (tick, index, ticks) => {
                                return tick + ' kWh'
                            }
                        },
                        gridLines: {
                            color: this.colors.grid
                        }
                    }, {
                        id: 'y2',
                        labelString: 'y2',
                        title: {
                            text: 'T2',
                            display: true,
                            fontColor: this.colors.font
                        },
                        position: 'right',
                        display: true,
                        ticks: {
                            beginAtZero: true,
                            fontColor: this.colors.font,
                            precision: 1,
                            maxTicksLimit: 7,
                            callback: (tick, index, ticks) => {
                                return tick + ' kW'
                            }
                        },
                        gridLines: {
                            color: this.colors.grid
                        }
                    }],
                    tooltipCallbacks: {
                        title: (tooltipItems, data) => {
                            const label = tooltipItems.reduce((a, b) => {
                                const part = data.datasets[b.datasetIndex].label
                                return a + ', ' + part
                            }, '')
                            const subTitle = tooltipItems && tooltipItems.length > 0 ? '\r\n' + moment(tooltipItems[0].label).unix() / 60 + ' min' : ''
                            return label.substring(2) + subTitle
                        },
                        label: (tooltipItems, data) => {
                            return tooltipItems.yLabel.toFixed(this.data.configuration.fractionDigits) + (data.labels[tooltipItems.datasetIndex] ?? '')
                        }
                    }
                }
            }
        }
    }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../../assets/sass/main.scss";
</style>
