<template>
    <div xs2 class="car column" :class="{ firstColumn, edit }">
        <div v-if="firstColumn" class="th"></div>
        <div v-if="car" class="td title">
            <template v-if="car.dino">
                <FormulateInput
                    v-if="edit"
                    type="text"
                    label="Bilens namn"
                    placeholder="Nuvarande bils namn"
                    v-model="car.title"
                />
                <template v-else>{{ car.title }}</template>
            </template>
            <template v-else>
                {{ car.make }} {{ car.model }}
            </template>
            <div class="mt-4">
                <template v-if="car.dino">
                    <v-avatar color="#879AA1" size="80">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" v-bind:svg-inline="''" v-bind:class="'pa-1'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path class="cls-1" d="M27 72a5 5 0 105 5 5 5 0 00-5-5zm0 8a3 3 0 113-3 3 3 0 01-3 3zM103 72a5 5 0 105 5 5 5 0 00-5-5zm0 8a3 3 0 113-3 3 3 0 01-3 3zM54 63h-4a1 1 0 000 2h4a1 1 0 000-2z"/><path class="cls-1" d="M127 63.38a7.12 7.12 0 00-6-5.34l-30.18-3.59L75 42a9.18 9.18 0 00-5.65-2c-30.36 0-29.26-.39-32 1.17L21 50.32 6.38 51.8A7.08 7.08 0 000 58.86v16.08a1 1 0 00.87 1l15.17 2A11 11 0 0038 78c1.13.06 54 0 54.1 0a11 11 0 0021.9 0h13a1 1 0 001-1c0-11.37.32-8.06-1-13.62zM125.29 65l.49 2H123a1 1 0 01-.69-1.71c.37-.39.6-.29 2.98-.29zm-56-23a7.13 7.13 0 014.41 1.53l15.14 11.91L84.16 57H47l3.75-15zm-25.47.3c.28-.42-.72-.3 4.81-.3L44.9 57c-1.55 0 .18.26-9.72-1.72zM2 63h5a1 1 0 011 1v2H2zm25 23a9 9 0 119-9 9 9 0 01-9 9zm76 0a9 9 0 119-9 9 9 0 01-9 9zm23-10h-12a11 11 0 00-21.9 0H38a11 11 0 00-21.89-.14L2 74.06V68h7a1 1 0 001-1v-3a3 3 0 00-3-3H2v-2.14a5.09 5.09 0 014.58-5.07l14.84-1.5c.33 0-.76.53 16.83-9.37a7.12 7.12 0 013.38-.92l-9 13.4a1 1 0 00.69 1.6c10.9 2.18 10.21 2 10.36 2h41c6.55-2.19 6.31-2 6.6-2.43L120.66 60a5.13 5.13 0 014 3c-1.56 0-2.77-.19-3.81.87A3 3 0 00123 69h3z"/></svg>
                    </v-avatar>
                </template>
                <template v-else>
                    <router-link :to="`/cars/${car.id}`">
                        <v-avatar size="80">
                            <img
                                :src="`cars/${car.img}`"
                                :alt="`${car.model} ${car.model}`"
                                class="carAvatar"
                            >
                        </v-avatar>
                    </router-link>
                </template>
            </div>
            <a class="evdb"
                v-if="!car.dino"
                :href="car.evdb ? car.evdb : `https://ev-database.org/search/?q=${car.make.replace(/ /g, '+')}+${car.model.replace(/ /g, '+')}+${car.version.replace(/ /g, '+')}`"
            >EV-Database</a>
        </div>
        <div v-else class="th fill"></div>

        <div class="th">Värde</div>
        <div v-if="car" class="td value">
            <InputCell
                v-if="car.dino"
                v-model="car.value"
                label=""
                :edit="edit"
                suffix="kr"
            />
            <template v-else>
                {{ numberWithSpaces(car.price) }}
            </template>
        </div>

        <div class="th">Beräknat restvärde efter tre år</div>
        <div v-if="car" class="td residualValue">
            <ResidualValueCell
                v-if="car.dino"
                v-model="car.residualValue"
                label=""
                :edit="edit"
                suffix="%"
            />
        </div>

        <div class="th">Förbrukning</div>
        <div v-if="car" class="td consumption">
            <InputCell
                v-if="car.dino"
                v-model="car.consumption"
                label="L/mil"
                :edit="edit"
                suffix="L/mil"
            />
            <ConsumptionCell
                v-else
                v-model="car.consumption.current"
                :car="car"
                label="Wh/km"
                :edit="edit"
                suffix="KWh/mil"
                :divider="100"
            />
        </div>

        <div class="th">El/Bränsle-kostnad</div>
        <div v-if="car" class="td">
            {{ numberWithSpaces(car.dino ? input.fuelCost : input.pricePerKWh) }} kr/L
        </div>

        <div class="th">Milkostnad</div>
        <div v-if="car" class="td">
            {{ numberWithSpaces(car.dino ? input.fuelCost * car.consumption : car.consumption.current * input.pricePerKWh / 100) }} kr/mil
        </div>

        <div class="th h3">Per år</div>
        <div v-if="car" class="td h3"></div>

        <div class="th">Skatt/år</div>
        <div v-if="car" class="td">
            <InputCell
                v-model="car.tax"
                label=""
                :edit="edit"
                suffix="kr/år"
            />
            <a v-if="edit" href="https://fu-regnr.transportstyrelsen.se/extweb/UppgifterAnnatFordon">Hitta fordonsskatt på Trafikverket</a>
        </div>

        <div class="th">Service och reparationer</div>
        <div v-if="car" class="td">
            <InputCell
                v-model="car.service"
                label=""
                :edit="edit"
                suffix="kr/år"
            />
        </div>

        <div class="th h3">Per månad</div>
        <div v-if="car" class="td h3"></div>

        <div class="th">Leasing</div>
        <div v-if="car" class="td">
            <InputCell
                v-model="car.leasing.price"
                label="Leasingpris"
                :edit="edit && !car.dino"
                suffix="kr/månad"
            />
        </div>

        <div class="th">Vinterhjul</div>
        <div v-if="car" class="td">
            <template v-if="car.dino">
                <InputCell
                    v-if="edit"
                    v-model="car.wheels"
                    label="kr/36 månader"
                    :edit="edit"
                    suffix=""
                    :divider="36"
                />
                {{ numberWithSpaces((car.wheels / 36).toFixed(0)) }}
            </template>
            <template v-else>
                <InputCell
                    v-model="car.leasing.wheels"
                    label="kr/månad"
                    :edit="edit"
                    suffix="kr/månad"
                />
            </template>
        </div>

        <div class="th">Körkostnad</div>
        <div v-if="car" class="td">
            {{ car.dino ? numberWithSpaces(car.consumption * input.fuelCost * input.km / 10 / 12) : numberWithSpaces((car.consumption.current * input.pricePerKWh / 100 * input.km / 10 / 12).toFixed(0)) }}
        </div>

        <div class="th">Försäkring</div>
        <div v-if="car" class="td">
            <InputCell
                v-if="car.dino"
                v-model="car.insurance"
                label=""
                :edit="edit"
                suffix="kr/månad"
            />
            <InputCell
                v-else
                v-model="car.leasing.insurance"
                label=""
                :edit="edit"
                suffix="kr/månad"
            />
        </div>

        <div class="th">Service</div>
        <div v-if="car" class="td">
            {{ car.dino ? numberWithSpaces((car.service / 12).toFixed(0)) : numberWithSpaces(car.service) }} kr
        </div>

        <div class="th">Skatt</div>
        <div v-if="car" class="td">
            {{ numberWithSpaces(car.tax / 12) }} kr
        </div>

        <div class="th x2">Månadskostnad utan<br />värdeminskning och amortering</div>
        <div v-if="car" class="td x2">
            <template v-if="car.dino">{{ numberWithSpaces(summarizeMonthlyCost(car).toFixed(0)) }} kr</template>
            <template v-else>
                <template v-if="calculation.include.amortization || calculation.include.depreciation">
                    {{ numberWithSpaces(summarizeMonthlyCost(car).toFixed(0)) }} kr
                </template>
            </template>
        </div>

        <template v-if="calculation.include.amortization">
            <div class="th">Amortering</div>
            <div v-if="car" class="td">
                <AmortizationCell
                    v-if="car.dino"
                    v-model="car.amortization"
                    :car="car"
                    label=""
                    :edit="edit"
                    suffix="kr"
                />
                <template v-else>
                    N/A
                </template>
            </div>
        </template>

        <template v-if="calculation.include.depreciation">
            <div class="th">Värdeminskning</div>
            <div v-if="car" class="td">
                <template v-if="car.dino">
                    {{ numberWithSpaces(car.value * (100 - car.residualValue) / 100 / 36) }} kr
                    <v-alert type="warning"
                        outlined
                        v-if="parseInt(car.value * (100 - car.residualValue) / 100 / 36) > parseInt(car.amortization)">
                        Amortering<br />understiger<br />värdeminskning
                    </v-alert>
                </template>
                <template v-else>
                    N/A
                </template>
            </div>
        </template>

        <div class="th h3">Summering</div>
        <div v-if="car" class="td h3"></div>

        <div class="th">Månadskostnad</div>
        <div v-if="car" class="td"
            :class="!car.dino && {
                green: summarizeMonthlyCost(car) < input.compareWith,
                red: summarizeMonthlyCost(car) > input.compareWith
            }"
        >
            <template v-if="car.dino">{{ numberWithSpaces(dinoCarRealMonthlyCost(car)) }} kr</template>
            <template v-else>{{ numberWithSpaces(summarizeMonthlyCost(car).toFixed(0)) }} kr</template>
        </div>

        <div class="th">Årskostnad</div>
        <div v-if="car" class="td light"
            :class="!car.dino && {
                green: summarizeMonthlyCost(car) < input.compareWith,
                red: summarizeMonthlyCost(car) > input.compareWith
            }"
        >
            <template v-if="car.dino">{{ numberWithSpaces(dinoCarRealMonthlyCost(car) * 12) }} kr</template>
            <template v-else>{{ numberWithSpaces(summarizeMonthlyCost(car).toFixed(0) * 12) }} kr</template>
        </div>

        <div class="th">Milkostnad</div>
        <div v-if="car" class="td light"
            :class="!car.dino && {
                green: summarizeMonthlyCost(car) < input.compareWith,
                red: summarizeMonthlyCost(car) > input.compareWith
            }"
        >
            <template v-if="car.dino">{{ numberWithSpaces(dinoCarRealMonthlyCost(car) * 12 / input.kmDividedByTen) }} kr/mil</template>
            <template v-else>{{ numberWithSpaces(summarizeMonthlyCost(car).toFixed(0) * 12 / input.kmDividedByTen) }} kr/mil</template>
        </div>

        <button v-if="car && !hideRemoveButton" @click="$emit('removeCar')">Ta bort</button>
    </div>
</template>

<script>
export default {
    props: {
        car: {
            type: Object,
            required: false,
            default: null
        },
        input: {
            type: Object,
            required: true
        },
        calculation: {
            type: Object,
            required: true
        },
        edit: {
            type: Boolean,
            required: true
        },
        firstColumn: {
            type: Boolean,
            required: true
        },
        hideRemoveButton: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data: () => ({
        data: null
    }),
    created() {
        this.data = this.value
    },
    methods: {
        numberWithSpaces(x) {
            if (!x && x !== 0) {
                return 'N/A'
            }
            var parts = x.toString().split('.')
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
            parts[1] = parts[1] ? parts[1].substring(0, 2) : null

            return parts[1] ? parts.join(',') : parts[0]
        },
        summarizeMonthlyCost(car) {
            if (car.dino) {
                return car.wheels / 12 + (car.consumption * this.input.fuelCost * this.input.km / 10) / 12 + car.leasing.insurance + car.service / 12 + car.tax / 12
            }
            return car.leasing.price + car.leasing.wheels + (car.consumption.current * this.input.pricePerKWh / 100 * this.input.km / 10 / 12) + car.leasing.insurance + car.service + car.tax / 12
        },
        dinoCarMonthlyCost(dinoCar) {
            return dinoCar.leasing.price + (dinoCar.wheels / 36) + (dinoCar.consumption * this.input.fuelCost * this.input.km / 10 / 12) + dinoCar.insurance + (dinoCar.service / 12) + (dinoCar.tax / 12)
        },
        dinoCarRealMonthlyCost(dinoCar) {
            let sum = this.dinoCarMonthlyCost(dinoCar)
            if (this.calculation.include.amortization) {
                sum += dinoCar.amortization
            }

            if (this.calculation.include.depreciation) {
                const depreciation = dinoCar.value * (100 - dinoCar.residualValue) / 100 / 36
                sum += depreciation > dinoCar.amortization ? depreciation - dinoCar.amortization : 0 // Only count depreciation if amortization is less than depreciation
            }
            return sum.toFixed(2)
        }
    },
    watch: {
        data: {
            handler(to, from) {
                if (to === '') {
                    to = 0
                }
                this.$emit('input', to)
            }
        },
        value: {
            handler(to, from) {
                this.data = to
            }
        }
    }
}
</script>
<style scoped lang="scss">
@import "@/assets/sass/main.scss";
@import "@/assets/sass/formulate.scss";
.column {
    scroll-snap-align: start;
    min-width: 280px;
    margin: 2px;
    padding: 0px;
    border-radius: 9px;
    background: #f9f9f9;

    >div {
        height: 36px;
        overflow: hidden;
        &.title {
            position: relative;
            text-align: center;
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.com/svgjs' width='279' height='160' preserveAspectRatio='none' viewBox='0 0 279 160'%3e%3cg mask='url(%26quot%3b%23SvgjsMask1093%26quot%3b)' fill='none'%3e%3crect width='279' height='160' x='0' y='0' fill='rgba(233%2c 239%2c 241%2c 1)'%3e%3c/rect%3e%3cpath d='M19 160L179 0L276.5 0L116.5 160z' fill='url(%23SvgjsLinearGradient1094)'%3e%3c/path%3e%3cpath d='M109.07000000000001 160L269.07 0L341.07 0L181.07 160z' fill='url(%23SvgjsLinearGradient1094)'%3e%3c/path%3e%3cpath d='M269 160L109 0L85 0L245 160z' fill='url(%23SvgjsLinearGradient1095)'%3e%3c/path%3e%3cpath d='M168.93 160L8.930000000000007 0L-129.07 0L30.930000000000007 160z' fill='url(%23SvgjsLinearGradient1095)'%3e%3c/path%3e%3cpath d='M141.95647876038842 160L279 22.95647876038842L279 160z' fill='url(%23SvgjsLinearGradient1094)'%3e%3c/path%3e%3cpath d='M0 160L137.04352123961158 160L 0 22.95647876038842z' fill='url(%23SvgjsLinearGradient1095)'%3e%3c/path%3e%3c/g%3e%3cdefs%3e%3cmask id='SvgjsMask1093'%3e%3crect width='279' height='160' fill='white'%3e%3c/rect%3e%3c/mask%3e%3clinearGradient x1='0%25' y1='100%25' x2='100%25' y2='0%25' id='SvgjsLinearGradient1094'%3e%3cstop stop-color='rgba(198%2c 208%2c 211%2c 1)' offset='0'%3e%3c/stop%3e%3cstop stop-opacity='0' stop-color='rgba(198%2c 208%2c 211%2c 1)' offset='0.66'%3e%3c/stop%3e%3c/linearGradient%3e%3clinearGradient x1='100%25' y1='100%25' x2='0%25' y2='0%25' id='SvgjsLinearGradient1095'%3e%3cstop stop-color='rgba(198%2c 208%2c 211%2c 1)' offset='0'%3e%3c/stop%3e%3cstop stop-opacity='0' stop-color='rgba(198%2c 208%2c 211%2c 1)' offset='0.66'%3e%3c/stop%3e%3c/linearGradient%3e%3c/defs%3e%3c/svg%3e") !important;
            height: 160px;
            color: $grey;
            padding-top: 16px;
        }
        &.fill:nth-child(2) {
            background: transparent !important;
            height: calc(160px - 36px);
        }

        &.x2 {
            height: 72px;
        }
        padding: 4px;
        &:nth-child(2n + 2) {
            background: $table_even;
        }
        &:nth-child(4n + 2) {
            background: $table_odd;
        }

        &.title {
            font-weight: 300;
            font-size: 26px;
        }
    }
    .th {
        color: $main_4_light;

        &.h3 {
            font-size: 20px;
            color: $main_4;
            position: relative;
        }
    }
}

.edit {
    >div {
        height: 80px;
        &.x2 {
            height: 80px;
        }
        &.fill:nth-child(2) {
            background: transparent !important;
            height: calc(160px - 80px);
        }
        overflow-y: auto;
    }
}

.firstColumn {
    >div {
        &:nth-child(odd) {
            background: $table_even;
        }
        &:nth-child(even) {
            background: $table_odd;
        }
    }
    div.td {
        display: none;
    }
}

.green, .red {
    &.light {
        opacity: 0.7;
    }
    color: #fff;
}

@media only screen and (min-width: 601px) {
    div.th {
        display: none;
    }

    .firstColumn {
        div.th {
            display: block;
        }
    }
}

.carAvatar {
    width: auto;
}

.evdb {
    position: absolute;
    left: 2px;
    bottom: 0px;
}

@media only screen and (max-width: 600px) {
    .column {
        min-width: 80vw;

        >div {
            &.title {
                height: 160px !important;
            }
            &:nth-child(4n + 1), &:nth-child(4n + 3) {
                font-size: 12px;
                height: auto;
            }
            &:nth-child(4n + 2), &:nth-child(4n + 4) {
                height: auto;
            }

            &:nth-child(4n + 1), &:nth-child(4n + 2) {
                background: $table_even;
            }
            &:nth-child(4n + 3), &:nth-child(4n + 4) {
                background: $table_odd;
            }
        }
    }
}
</style>
