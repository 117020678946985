<template>
    <div class="chart" :class="classes">
        <div v-if="noData" class="center noData">
            <h1 class="grey--text">{{ translate('common.noData') }}</h1>
        </div>
        <!-- Canvas for Chart.js -->
        <canvas v-else :id="'canvas_' + id"></canvas>
    </div>
</template>

<script>
import Chart from 'chart.js'
import moment from 'moment'
import { Guid } from '@/helpers'
import chartColors from './colors'
const guid = new Guid()
const colors = chartColors()

// Draw lines
const originalLineDraw = Chart.controllers.line.prototype.draw
Chart.helpers.extend(Chart.controllers.line.prototype, {
    beforeDraw() {
        const areas = this.chart.config.data.areas
        if (!areas || areas.length === 0) {
            return
        }

        // Clear old area
        var ctx = this.chart.chart.ctx
        var xaxis = this.chart.scales['x-axis-0']
        var yaxis = this.chart.scales['y-axis-0']
        areas.forEach(area => {
            var yBeginPixel = yaxis.getPixelForValue(area.begin)
            var yEndPixel = yaxis.getPixelForValue(area.end)
            ctx.clearRect(xaxis.left, Math.min(yBeginPixel, yEndPixel), xaxis.right - xaxis.left, Math.max(yBeginPixel, yEndPixel) - Math.min(yBeginPixel, yEndPixel))
        })
    },
    draw() {
        const areas = this.chart.config.data.areas
        if (!areas || areas.length === 0) {
            // Apply the original draw function for the line chart.
            originalLineDraw.apply(this, arguments)
            return
        }

        // Draw
        var ctx = this.chart.chart.ctx
        var xaxis = this.chart.scales['x-axis-0']
        var yaxis = this.chart.scales['y-axis-0']
        areas.forEach(area => {
            let begin = area.begin < area.end ? area.begin : area.end
            let end = area.begin < area.end ? area.end : area.end
            let line = {
                upper: true,
                lower: true
            }

            // Set begin and end values based on chart scope
            if ((begin < yaxis.start && end < yaxis.start) || (begin > yaxis.end && end > yaxis.end)) {
                return
            } else {
                begin = begin > yaxis.start ? begin : yaxis.start
                end = end < yaxis.end ? end : yaxis.end
                line.upper = begin > yaxis.start
                line.lower = end < yaxis.end
            }

            var yBeginPixel = yaxis.getPixelForValue(begin)
            var yEndPixel = yaxis.getPixelForValue(end)
            ctx.save()
            // The fill style of the rectangle we are about to fill.
            ctx.fillStyle = area.fillStyle
            // Fill the rectangle that represents the highlight region. The parameters are the closest-to-starting-point pixel's x-coordinate,
            // the closest-to-starting-point pixel's y-coordinate, the width of the rectangle in pixels, and the height of the rectangle in pixels, respectively.
            ctx.fillRect(xaxis.left, Math.min(yBeginPixel, yEndPixel), xaxis.right - xaxis.left, Math.max(yBeginPixel, yEndPixel) - Math.min(yBeginPixel, yEndPixel))

            ctx.restore()

            ctx.strokeStyle = area.lineColor
            ctx.setLineDash([5, 3])
            ctx.beginPath()
            ctx.moveTo(xaxis.left, yBeginPixel)
            if (line.upper) {
                ctx.lineTo(xaxis.right, yBeginPixel)
            }
            ctx.moveTo(xaxis.left, yEndPixel)
            if (line.lower) {
                ctx.lineTo(xaxis.right, yEndPixel)
            }
            ctx.stroke()
            ctx.setLineDash([5, 0])
            ctx.restore()
        })

        // Apply the original draw function for the line chart.
        originalLineDraw.apply(this, arguments)
    }
})

export default {
    props: {
        telemetry: {
            type: Array,
            required: true
        },
        data: {
            type: Object,
            required: true
        },
        filteredDevices: {
            type: Array,
            required: false,
            default: null
        },
        mixins: {
            type: Array,
            required: false,
            default: null
        },
        dark: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    data: () => ({
        chart: null,
        id: guid.generate(),
        weekLabels: null,
        yearLabels: null,
        dataToPlot: [],
        toggle_time: 1,
        configurationHash: null,
        noData: false
    }),
    mounted() {
        this.drawChart(this.telemetry)
    },
    computed: {
        hasValidUrl() {
            return this.filteredDevices && this.filteredDevices.length > 0
        },
        classes() {
            if (!this.data.configuration) {
                return
            }
            let result = {
                theme: true
            }
            result[this.data.configuration.theme] = true
            return result
        },
        colors() {
            return {
                grid: this.dark ? 'rgba(25, 25, 25, .1)' : 'rgba(255, 255, 255, 0.2)',
                font: this.dark ? 'rgba(25, 25, 25, .5)' : 'rgba(255, 255, 255, 0.6)',
                point: {
                    border: this.dark ? 'rgba(25, 25, 25, 0.2)' : 'rgba(255, 255, 255, 0.2)',
                    borderHover: this.dark ? 'rgba(25, 25, 25, 0.2)' : 'rgba(255, 255, 255, 0.2)'
                }
            }
        }
    },
    methods: {
        toFixed(input) {
            if (input) {
                return input.toFixed(this.data.configuration.fractionDigits)
            } else {
                return 0
            }
        },
        drawChart(dataToPlot, noAnimation) {
            if (!dataToPlot || dataToPlot.length === 0 || dataToPlot[0].data.length === 0) {
                this.noData = true
                return
            }
            this.noData = false
            let data = {
                labels: dataToPlot.map(d => d.suffix), // ['ETT', 'ET2'], // dataToPlot[0].length > 7 ? dataToPlot[0].map(item => item.date) : dataToPlot[0].map(item => item.day),
                datasets: dataToPlot.map((r, i) => {
                    const label = this.data.label ? this.data.label(r, i) : r.path
                    const data = r.data.map(d => {
                        return d
                    })
                    return {
                        label: label,
                        suffix: r.suffix,
                        yAxisID: r.yAxisID,
                        data: data,
                        type: r.type,

                        fill: false,
                        borderColor: r.color ? r.color : colors[i],
                        borderWidth: 2,
                        pointBackgroundColor: 'transparent',
                        pointBorderColor: r.color ? r.color : colors[i],
                        pointBorderWidth: 1.5,
                        pointHoverBorderColor: this.colors.point.borderHover,
                        pointHoverBorderWidth: 3,
                        lineTension: 0,
                        datalabels: {
                            display: false
                        }
                    }
                })
            }

            // Configure upper and lower chart lines
            if (this.data.configuration.areas !== null) {
                data.areas = this.data.configuration.areas
            }

            if (this.chart == null) {
                this.createChart(data)
            }

            if (!this.data.configuration.setAutomaticMinMax) {
                const arrayWithValues = dataToPlot.find(a => a.data && a.data.length > 0)
                if (arrayWithValues && arrayWithValues.data && arrayWithValues.data.length > 0) {
                    const minTime = arrayWithValues.data[0]
                    const maxTime = arrayWithValues.data[arrayWithValues.data.length - 1]
                    this.chart.options.scales.xAxes[0].ticks.min = minTime
                    this.chart.options.scales.xAxes[0].ticks.max = maxTime
                }
            }

            const showLegend = this.data.configuration.showLegend != null ? this.data.configuration.showLegend : true
            this.chart.options.legend.display = showLegend

            this.chart.data = data
            if (noAnimation) {
                this.chart.update('none')
            } else {
                this.chart.update()
            }
        },
        createChart(data) {
            var canvas = document.getElementById('canvas_' + this.id)
            // Apply multiply blend when drawing datasets
            var multiply = {
                beforeDatasetsDraw(chart, options, el) {
                    chart.ctx.globalCompositeOperation = 'multiply'
                },
                afterDatasetsDraw(chart, options) {
                    chart.ctx.globalCompositeOperation = 'source-over'
                }
            }
            const showLegend = this.data.configuration.showLegend ? this.data.configuration.showLegend : true
            var config = {
                type: 'line',
                data: data,
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    elements: {
                        point: {
                            radius: 6,
                            hitRadius: 6,
                            hoverRadius: 7
                        }
                    },
                    layout: {
                        padding: {
                            top: 24,
                            left: 12,
                            right: 12,
                            bottom: 24
                        }
                    },
                    legend: {
                        display: showLegend,
                        labels: {
                            fontColor: this.colors.font
                        },
                        position: 'right'
                    },
                    tooltips: {
                        displayColors: false,
                        bodyFontSize: 14,
                        callbacks: this.data.configuration.tooltipCallbacks ?? {
                            title: (tooltipItems, data) => {
                                const label = tooltipItems.reduce((a, b) => {
                                    const part = data.datasets[b.datasetIndex].label
                                    return a + ', ' + part
                                }, '')
                                const subTitle = tooltipItems && tooltipItems.length > 0 ? '\r\n' + moment(tooltipItems[0].label).format('YYYY-MM-DD HH:mm') : ''
                                return label.substring(2) + subTitle
                            },
                            label: (tooltipItems, data) => {
                                return tooltipItems.yLabel.toFixed(this.data.configuration.fractionDigits) + (data.labels[tooltipItems.datasetIndex] ? data.labels[tooltipItems.datasetIndex] : '')
                            }
                        }
                    },
                    scales: {
                        xAxes: this.data.configuration.xAxes ?? [{
                            distribution: 'linear',
                            type: 'time',
                            time: {
                                minUnit: 'hour',
                                displayFormats: {
                                    day: 'D/M',
                                    hour: 'D/M HH:mm'
                                }
                            },
                            position: 'bottom',
                            maxTicksLimit: 10,
                            gridLines: {
                                color: this.colors.grid
                            },
                            ticks: {
                                fontColor: this.colors.font, // this.dark ? this.darkBorderColor : this.lightBorderColor
                                maxTicksLimit: 6
                            }
                        }],
                        yAxes: this.data.configuration.yAxes ?? [{
                            display: true,
                            ticks: {
                                beginAtZero: false,
                                fontColor: this.colors.font,
                                precision: 1,
                                maxTicksLimit: 7
                            },
                            gridLines: {
                                color: this.colors.grid
                            }
                        }]
                    }
                },
                plugins: [multiply]
            }

            const groupBy = this.data.configuration.groupBy
            if (groupBy) {
                config.options.scales.xAxes[0].unitStepSize = groupBy === 'd' ? 1 : 6
                config.options.scales.xAxes[0].unit = groupBy === 'd' ? 'day' : 'hour'
            }

            this.chart = new Chart(canvas, config) // eslint-disable-line no-undef
        },
        didClick() {
            if (!this.hasValidUrl) {
                this.$emit('showProperties')
            }
        }
    },
    watch: {
        telemetry: {
            handler(to, from) {
                this.drawChart(this.telemetry)
            },
            deep: true
        },
        'data.configuration': {
            handler(to, from) {
                this.drawChart(this.telemetry, false)
                // this.chart.update('none')
            },
            deep: true
        }
    }
}
</script>

<style scoped lang="scss">
.chart {
    color: #eee;
    &.dark {
        color: darkslategrey;
    }
}

.legends {
  padding-top: 20px;
  overflow: hidden;
}

.legend {
  display: block;
  width: 8px;
  height: 8px;
  margin-top: 15px;
  margin-bottom: 15px;
  border-radius: 50%;
}

@keyframes bg
{
  0% {
    background-position: 0% 50%
}
50% {
    background-position: 100% 50%
}
100% {
    background-position: 0% 50%
}
}

</style>
